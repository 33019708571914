import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Container,
  Divider,
  Grid2,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import SummarizeRoundedIcon from '../../assets/chat_bot_icon.png';
import StructuredDataIcon from '../../assets/strutuctured_data_icon.png';
import logo from '../../assets/giuribot_logo_extractor.png';
import { Fab } from '@mui/material';
import SupportIcon from '@mui/icons-material/Support';
import { ArrowForward, Search, Send } from '@mui/icons-material';
import { useRecoilState } from 'recoil';
import { appAtom } from '../../state/app.atom';

export const Home = () => {
  const navigate = useNavigate();
  const [appState, setAppState] = useRecoilState(appAtom);

  const [input, setInput] = useState<string>('');

  function handleSearchChange(
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void {
    setInput(event.target.value);
  }

  function goToSearch() {
    navigate(`/panel/giustizia-amministrativa?search=${input}`);
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      goToSearch();
    }
  };

  return (
    <Container>
      <Box
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
        }}
      >
        <Fab
          color="primary"
          aria-label="contact support"
          onClick={() => {
            window.open('mailto:info@giuribot.com');
          }}
        >
          <SupportIcon
            sx={{
              color: 'white',
            }}
          />
        </Fab>
      </Box>
      <Box width={'100%'} height={400} margin={'0px'} sx={{}} display={'flex'}>
        <Box width={'100%'} height={400} sx={{}}>
          <Box display={'flex'} justifyContent={'center'}>
            <Box mt={10} height={90} component={'img'} src={logo} />
          </Box>

          <Typography
            mt={3}
            fontSize={{
              xs: 20,
              sm: 25,
              md: 30,
            }}
            textAlign={'center'}
            color="rgb(80, 80, 80)"
          >
            Cercare Decisioni e Pareri della Giustizia Amministrativa
          </Typography>
          <Box margin={'0px 5%'}>
            <Box mt={3} display={'flex'} justifyContent={'center'}>
              <OutlinedInput
                placeholder="Parole chiave oppure descrive quello che cerchi"
                startAdornment={
                  <Stack
                    sx={{
                      px: 1,
                    }}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <Search />
                  </Stack>
                }
                size="medium"
                fullWidth
                endAdornment={
                  <Button
                    variant="contained"
                    disableElevation
                    sx={{
                      color: 'white',
                      px: 3,

                      backgroundColor: 'black',
                      fontWeight: 600,
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                      },
                    }}
                    onClick={goToSearch}
                  >
                    <Typography
                      color="white"
                      mr={0.5}
                      sx={{
                        fontWeight: 600,
                        fontSize: 15,
                        pl: 1.0,
                      }}
                    >
                      Cercare
                    </Typography>
                    <ArrowForward
                      sx={{
                        color: 'white',
                        width: 15,
                        height: 15,
                        pr: 1.0,
                      }}
                    />
                  </Button>
                }
                sx={{
                  maxWidth: 600,
                }}
                onChange={handleSearchChange}
                onKeyUp={handleKeyDown}
              />
            </Box>
          </Box>
        </Box>
      </Box>

      {appState.unauthenticatedLogin == false ? (
        <Divider
          sx={{
            my: 2,
          }}
        >
          <Typography
            px={10}
            fontSize={16}
            fontWeight={600}
            color="rgb(150, 150, 150)"
          >
            Alre funzioni
          </Typography>
        </Divider>
      ) : null}
      {appState.unauthenticatedLogin === false ? (
        <Box
          display="flex"
          flexWrap="wrap"
          rowGap={4}
          columnGap={2}
          justifyContent={'center'}
          flexDirection={'row'}
        >
          {true !== true ? (
            <Box flexBasis={{ xs: '100%', md: '48%' }}>
              <Card
                elevation={0}
                variant="outlined"
                sx={{
                  padding: 8,
                }}
              >
                <Box
                  height={150}
                  width={'100%'}
                  display={'flex'}
                  justifyContent={'center'}
                >
                  <Box
                    height={120}
                    component={'img'}
                    src={SummarizeRoundedIcon}
                  />
                </Box>

                <Typography textAlign={'center'} fontWeight={600}>
                  RIASSUNTI DI DOCUMENTI
                </Typography>

                <CardContent>
                  <Box minHeight={90}>
                    <Typography textAlign={'center'}>
                      Estrai automaticamente i riassunti dei documenti giuridici
                      per avere una visione d'insieme dei contenuti.
                    </Typography>
                  </Box>
                </CardContent>
                <CardActionArea disableRipple disableTouchRipple>
                  <Button
                    fullWidth
                    disableElevation
                    variant="contained"
                    onClick={() => {
                      navigate('/panel/chat-docs');
                    }}
                  >
                    Andare
                  </Button>
                </CardActionArea>
              </Card>
            </Box>
          ) : null}
          <Box flexBasis={{ xs: '100%', md: '48%' }}>
            <Card
              elevation={0}
              variant="outlined"
              sx={{
                padding: 8,
              }}
            >
              <Box
                height={150}
                width={'100%'}
                display={'flex'}
                justifyContent={'center'}
              >
                <Box height={120} component={'img'} src={StructuredDataIcon} />
              </Box>

              <Typography textAlign={'center'} fontWeight={600}>
                ESTRAZIONE STRUTTURATA DI DATI
              </Typography>

              <CardContent>
                <Box minHeight={90}>
                  <Typography textAlign={'center'}>
                    Estrai automaticamente dati strutturati dai documenti
                    giuridici per facilitare l'analisi e l'elaborazione delle
                    informazioni.
                  </Typography>
                </Box>
              </CardContent>
              <CardActionArea disableRipple disableTouchRipple>
                <Button
                  fullWidth
                  disableElevation
                  onClick={() => {
                    navigate('/panel/structured-output');
                  }}
                  variant="contained"
                >
                  Andare
                </Button>
              </CardActionArea>
            </Card>
          </Box>
          {true !== true ? (
            <Box flexBasis={{ xs: '100%', md: '48%' }}>
              <Card
                elevation={0}
                variant="outlined"
                sx={{
                  minHeight: '100%',
                }}
              >
                <Box
                  height={150}
                  width={'100%'}
                  display={'flex'}
                  justifyContent={'center'}
                >
                  {/*  <StructuredDataIcon
        sx={{
          width: '60px',
          height: '60px',
          marginTop: 'auto',
          marginBottom: 'auto',
          color: 'rgb(219,164,22)',
        }}
        />*/}
                </Box>

                <Typography textAlign={'center'} fontWeight={600}>
                  COMPILAZIONE DI TEMPLATE
                </Typography>

                <CardContent>
                  <Box>
                    <Typography textAlign={'center'}>
                      Compila automaticamente i template giuridici con i dati
                      estratti dai documenti, risparmiando tempo e riducendo gli
                      errori.
                    </Typography>
                  </Box>
                </CardContent>
                <CardActionArea disableRipple disableTouchRipple>
                  <Button disableElevation fullWidth variant="contained">
                    Andare
                  </Button>
                </CardActionArea>
              </Card>
            </Box>
          ) : null}
        </Box>
      ) : null}

      <Box height={100}></Box>
    </Container>
  );
};
