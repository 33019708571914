import { Box, Button, Divider, Grid, Link, Typography } from '@mui/material';
import XIcon from '@mui/icons-material/X';
import { useTranslation } from 'react-i18next';
import GiuribotLogo from '../../../assets/giuribot_logo_extractor.png';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function AppFooter() {
  const { t, i18n } = useTranslation();

  const [language, setLanguage] = useState(i18n.language);
  const handleChange = (event: { target: { value: string } }) => {
    let newValue = event.target.value as string;
    setLanguage(newValue);
    i18n.changeLanguage(newValue);
  };

  const currentYear = new Date().getFullYear();

  const navigator = useNavigate();

  useEffect(() => {
    setLanguage(i18n.language);
  }, []);
  return (
    <Box
      component={'footer'}
      sx={{
        backgroundColor: `#222222`,
      }}
      px={{ xs: 5, sm: 5, md: 5, lg: 5 }}
      paddingTop={'80px'}
      paddingBottom={20}
    >
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          display={'flex'}
          justifyContent={'start'}
        >
          <Link
            href="/"
            sx={{
              marginTop: 'auto',
              marginBottom: 'auto',
            }}
          >
            <Box component={'img'} src={GiuribotLogo} height={'40px'} />
          </Link>

          <Box
            sx={{
              marginRight: '10px',
              minWidth: '150px',
            }}
            display={{
              xs: 'block',
              sm: 'none',
            }}
            marginLeft={2}
          ></Box>
        </Grid>

        <Grid item xs={12} sm={12} md={8}>
          <Box
            display={'flex'}
            width={'100%'}
            justifyContent={{
              xs: 'start',
              sm: 'start',
              md: 'end',
            }}
            marginTop={{
              xs: 2,
              sm: 2,
            }}
          >
            <Link
              color={'rgb(245, 245, 245)'}
              fontSize={{
                xs: 12,
                sm: 14,
              }}
              sx={{
                marginRight: '10px',
                marginLeft: '20px',
                marginTop: 'auto',
                marginBottom: 'auto',
              }}
              onClick={(e) => {
                e.preventDefault();
                window.open('/terms-and-conditions', '_blank');
              }}
            >
              {t('termsAndConditions')}
            </Link>
          </Box>
        </Grid>
      </Grid>

      <Divider
        sx={{
          marginTop: '32px',
          marginBottom: '32px',
          bgcolor: 'gray',
        }}
      />

      <Box display={'flex'} justifyContent={'space-between'}>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Typography
              fontSize={12}
              color={'white'}
              minWidth={'250px'}
              textAlign={'start'}
            >
              © {currentYear} Giuribot. {t('allRightsReserved')}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Box
              display={'flex'}
              justifyContent={{
                xs: 'start',
                sm: 'end',
              }}
              marginTop={{
                xs: 1,
                sm: 0,
              }}
            >
              {/*<Button
                sx={{
                  minWidth: '0px',
                  color: 'white',
                }}
              >
                <FacebookIcon />
              </Button> */}

              <Button
                sx={{
                  minWidth: '0px',
                  color: 'white',
                }}
                onClick={() => {
                  window.open('https://x.com/Giuribot', '_blank');
                }}
              >
                <XIcon />
              </Button>

              {/*<Button
                sx={{
                  minWidth: '0px',
                  color: 'white',
                }}
                onClick={() => {
                  window.open('https://www.instagram.com/piattoqr/');
                }}
              >
                <InstagramIcon />
              </Button>*/}

              {/*<LinkedInIcon sx={{ marginRight: "10px", marginLeft: "20px" }} />*/}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
