import { Box, ButtonBase, Menu, MenuItem } from '@mui/material';
import { ChatHistory } from '../../../../models/chat_history.model';
import Typography from '../../../Landing/components/Typography';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useState } from 'react';

interface ConversationItemProps {
  chat: ChatHistory;
  onPressed?: (chat: ChatHistory) => void;
  onShare?: (chat: ChatHistory) => void;
  onRename?: (chat: ChatHistory) => void;
  onDelete?: (chat: ChatHistory) => void;
}

const ConversationItem = ({ chat }: ConversationItemProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isHovered, setIsHovered] = useState<boolean>(false);

  // Handler for when mouse enters the component
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  // Handler for when mouse leaves the component
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <Box
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        sx={{
          borderRadius: 2.0,
          display: 'flex',
          p: 1,

          backgroundColor: isHovered ? 'rgb(220, 220, 220)' : 'none',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography>{chat.title}</Typography>

        {isHovered && (
          <ButtonBase
            id="options-menu"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 10.0,
            }}
          >
            <MoreHorizIcon />
          </ButtonBase>
        )}
      </Box>

      <Menu
        id="options-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleClose}>Share</MenuItem>
        <MenuItem onClick={handleClose}>Rename</MenuItem>
        <MenuItem onClick={handleClose}>Archive</MenuItem>
        <MenuItem onClick={handleClose}>Delete</MenuItem>
      </Menu>
    </>
  );
};

export default ConversationItem;
