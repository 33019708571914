import { ButtonBase, ButtonProps, extendTheme } from '@mui/material';
import React, { ReactNode } from 'react';
import Typography from '../../Landing/components/Typography';
import LanguageIcon from '@mui/icons-material/Language';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';

interface ChatIconButtonProps extends ButtonProps {
  height?: number;
  width?: number | string;
  borderRadius?: number;
  buttonType: 'search' | 'reason' | 'ai_search';
  state: boolean;
  onStateChanged: (value: boolean) => void;
}

const ChatIconButton = ({
  height = 45,
  width,
  borderRadius = 45,
  buttonType,
  children,
  state,
  onStateChanged,
  ...props
}: ChatIconButtonProps) => {
  const primaryColor = extendTheme().colorSchemes.light?.palette.primary.light;
  return (
    <ButtonBase
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: `1px solid ${state ? primaryColor : 'rgb(200, 200, 200)'}`,
        height: height,
        width: width,
        paddingLeft: 1.2,
        paddingRight: 1.2,
        borderRadius: borderRadius,
        backgroundColor: `${state ? primaryColor + '50' : 'transparent'}`,
      }}
      {...props}
      onClick={() => onStateChanged(!state)}
    >
      {buttonType == 'search' ? (
        <LanguageIcon
          sx={{
            color: `${state ? primaryColor : 'rgb(150, 150, 150)'}`,
          }}
        />
      ) : (
        <LightbulbOutlinedIcon
          sx={{
            color: `${state ? primaryColor : 'rgb(150, 150, 150)'}`,
          }}
        />
      )}

      <Typography
        sx={{
          ml: 0.5,
          color: `${state ? primaryColor : 'rgb(150, 150, 150)'}`,
          fontWeight: 500,
        }}
      >
        {children}
      </Typography>
    </ButtonBase>
  );
};

export default ChatIconButton;
