export const privacyPolicyMarkdown = `
# **Privacy Policy e Termini e Condizioni d'Uso di Giuribot**

### **1\. Introduzione**

Benvenuto/a in Giuribot, il servizio di assistenza automatizzata progettato per supportarti nella ricerca giurisprudenziale. Grazie a un sistema avanzato, Giuribot consente di individuare tra i precedenti di giustizia amministrativa non solo corrispondenze linguistiche, ma anche concettuali, offrendo inoltre sintesi precise e dettagliate dei documenti giuridici (di seguito, “Servizi”). Utilizzando questo servizio, accetti i seguenti Termini e Condizioni d'Uso e la Privacy Policy. Se non sei d'accordo con questi termini, ti preghiamo di cessare immediatamente l'uso del chatbot. Per qualsiasi informazione o chiarimento aggiuntivo puoi contattarci all’indirizzo e-mail info@giuribot.com 

Il Titolare del trattamento dei dati personali è Solfop LLC con sede in Address 1416, 701 Tillery Street Unit 12, Austin, TX, Travis, US, 78702, e indirizzo email info@giuribot.com (di seguito, “Titolare” o “Società”). Lo scopo del presente documento è informare l'interessato sui dati personali raccolti e trattati attraverso il servizio Giuribot.

I Servizi sono destinati agli utenti che hanno almeno 18 anni di età. Tutti gli utenti minorenni nella giurisdizione in cui risiedono (generalmente di età inferiore ai 18 anni) devono avere l'autorizzazione e la supervisione diretta del proprio genitore o tutore per utilizzare i Servizi. Se l'utente è minorenne, deve chiedere al proprio genitore o tutore di leggere e accettare le presenti Condizioni legali prima di utilizzare i Servizi.

### **2\. Modifiche ai Termini**

Il Titolare si riserva il diritto di modificare e aggiornare i presenti Termini e Condizioni d'Uso e la Privacy Policy in qualsiasi momento. Le modifiche saranno vincolanti non appena pubblicate sul Sito o comunicate agli utenti tramite il chatbot. L'uso continuato del servizio dopo la pubblicazione delle modifiche implica l'accettazione delle stesse. In caso di mancata accettazione delle modifiche, l’utente è tenuto a cessare l’utilizzo del servizio e può richiedere la cancellazione dei propri dati personali.

## **Privacy Policy**

### **3\. Categorie di Dati Personali Trattati**

Il Titolare raccoglie e tratta i seguenti dati personali forniti volontariamente dall'interessato durante l'uso del chatbot Giuribot:

* **Dati di contatto**: Nome, cognome, indirizzo email, immagini, credenziali di autenticazione, ed eventuali ulteriori informazioni fornite dall'utente.  
* **Dati fiscali e di pagamento**:  informazioni relative ai pagamenti e ai dati fiscali, che possono essere trasmessi a Stripe per l'elaborazione delle transazioni, nel rispetto dei termini e delle condizioni di Stripe.  
* **Dati tecnici**: dati di log, cronologia delle interazioni con il chatbot e altre informazioni raccolte automaticamente durante l'uso del servizio.  
* **Cookie e tecnologie simili**: Giuribot utilizza cookie e tecnologie simili per raccogliere dati sugli utenti e migliorare l’esperienza di utilizzo del chatbot. 

### **4\. Finalità del Trattamento e Base Giuridica**

I dati personali degli utenti sono raccolti e trattati per le seguenti finalità:

* Fornitura del servizio: Per consentire all'utente di interagire con il chatbot, ricevere risposte personalizzate, e ottenere assistenza automatizzata. Base giuridica: Esecuzione di un contratto (Art. 6, par. 1, lett. b) GDPR).  
* Miglioramento del servizio: Per analizzare e migliorare l'efficienza, la qualità e la sicurezza del chatbot. Base giuridica: Legittimo interesse del Titolare (Art. 6, par. 1, lett. f) GDPR).  
* Comunicazioni promozionali: Se l'utente ha espresso il proprio consenso, i dati possono essere utilizzati per inviare comunicazioni promozionali o newsletter. Base giuridica: Consenso (Art. 6, par. 1, lett. a) GDPR).  
* Adempimento di obblighi legali: Per adempiere a obblighi previsti dalla legge o richieste delle autorità competenti. Base giuridica: Obbligo legale (Art. 6, par. 1, lett. c) GDPR).

### **5\. Conservazione dei Dati**

I dati personali degli utenti saranno conservati per il tempo strettamente necessario per le finalità descritte sopra, o per adempiere agli obblighi legali. In assenza di obblighi specifici, i dati saranno cancellati entro 90 giorni dalla cessazione dell'uso del servizio.

### **6\. Diritti dell'Interessato**

In conformità al GDPR, l'interessato ha il diritto di:

* **Accesso**: Ottenere conferma che sia o meno in corso un trattamento dei propri dati personali e, in tal caso, accedere a tali dati.  
* **Rettifica**: Chiedere la correzione di dati inesatti o incompleti.  
* **Cancellazione**: Richiedere la cancellazione dei propri dati personali ("diritto all'oblio"), se sussistono le condizioni previste dalla normativa.  
* **Limitazione del trattamento**: Ottenere la limitazione del trattamento dei propri dati nelle ipotesi previste dal GDPR.  
* **Portabilità dei dati**: Ricevere i propri dati personali in un formato strutturato, di uso comune e leggibile da dispositivo automatico.  
* **Opposizione**: Opporsi in qualsiasi momento al trattamento dei dati personali basato sul legittimo interesse del Titolare.  
* **Revoca del consenso**: Revocare in qualsiasi momento il consenso precedentemente prestato, senza che ciò pregiudichi la legittimità del trattamento basato sul consenso prima della revoca.  
* **Reclamo**: Presentare un reclamo all’autorità di controllo competente in materia di protezione dei dati.

Per esercitare i propri diritti, l'interessato può contattare il Titolare all'indirizzo email info@giuribot.com

### **7\. Data protection**

Abbiamo implementato misure di sicurezza tecniche e organizzative adeguate e ragionevoli per proteggere la sicurezza delle informazioni personali che trattiamo.   
La nostra applicazione utilizza un solido sistema di autenticazione basato sui ruoli, progettato per garantire che solo gli utenti autorizzati possano accedere a specifici set di dati. Questo sistema assegna meticolosamente i ruoli e le corrispondenti autorizzazioni, limitando così l'accesso alle informazioni sensibili esclusivamente a coloro che hanno ottenuto l'autorizzazione appropriata. Qualsiasi tentativo da parte di utenti non autorizzati o malintenzionati di accedere ai dati al di là dei loro diritti designati sarà automaticamente negato. Per salvaguardare le interazioni tra gli utenti e i nostri server, utilizziamo un protocollo di comunicazione criptato, ovvero HTTPS (HyperText Transfer Protocol Secure). Questo protocollo sfrutta il Transport Layer Security (TLS) per fornire una crittografia end-to-end, assicurando che tutti i dati trasmessi tra il dispositivo dell'utente e i nostri server rimangano riservati e a prova di manomissione. Questa misura di sicurezza garantisce che le attività dell'utente, compreso il recupero dei dati, gli aggiornamenti e altre comunicazioni, siano condotte in modo da proteggere da intercettazioni, intercettazioni e attacchi man-in-the-middle.

In aggiunta ai controlli di accesso e alla comunicazione sicura abbiamo implementato meccanismi di limitazione della velocità per prevenire il sovraccarico del server dovuto ad attacchi denial-of-service (DoS) o distributed denial-of-service (DDoS). Questo sistema limita il numero di richieste che un utente o un indirizzo IP può effettuare in un determinato lasso di tempo, attenuando in modo efficace i tentativi di sovraccarico delle risorse del nostro server.

Il nostro server è configurato per convalidare e sanificare tutti i dati in entrata per prevenire attacchi di tipo SQL injection o cross-site scripting (XSS). Questo garantisce che solo gli input sicuri e previsti vengano elaborati dalla nostra applicazione. 

Utilizziamo pratiche sicure di gestione delle sessioni, compreso l'uso di cookie sicuri, HttpOnly e SameSite, per proteggere dagli attacchi di dirottamento e fissazione delle sessioni.

Manteniamo registri completi per tutte le interazioni con il server, che vengono costantemente monitorati per individuare modelli insoliti che potrebbero indicare un attacco, come ad esempio un numero insolito di tentativi di accesso falliti o richieste di accesso da indirizzi IP sospetti.

Tuttavia, nonostante le nostre misure di sicurezza e i nostri sforzi per proteggere le informazioni dell'utente, nessuna trasmissione elettronica su Internet o tecnologia di archiviazione delle informazioni può essere garantita come sicura al 100%, pertanto non possiamo promettere o garantire che hacker, criminali informatici o altre terze parti non autorizzate non saranno in grado di sconfiggere la nostra sicurezza e raccogliere, accedere, rubare o modificare impropriamente le informazioni dell'utente. Anche se faremo del nostro meglio per proteggere le informazioni personali dell'utente, la trasmissione di informazioni personali da e verso i nostri Servizi è a rischio dell'utente. L'utente dovrebbe accedere ai Servizi solo in un ambiente sicuro.

## **Termini e Condizioni d'Uso**

### **8\. Descrizione del Servizio**

Giuribot è un chatbot giuridico progettato per offrire informazioni generali e assistenza automatizzata, elaborando dati provenienti da sentenze giuridiche mediante l'impiego di una sofisticata ricerca vettoriale. Pur impegnandosi a fornire risposte precise, Giuribot non può garantire la completezza o l’affidabilità delle informazioni fornite, che non devono in alcun caso essere considerate come consulenze legali professionali.

### **9\. Uso Consentito**

L'utente è tenuto a utilizzare Giuribot in conformità con i presenti Termini e Condizioni. In particolare, è vietato:

* Utilizzare il chatbot per scopi illegali o non autorizzati.  
* Inviare contenuti offensivi, discriminatori, violenti o altrimenti inappropriati.  
* Violare i diritti di terzi, inclusi diritti di proprietà intellettuale o diritto alla privacy.  
* Utilizzare il chatbot per inviare spam, malware o contenuti dannosi.

Qualsiasi violazione di questi termini potrebbe comportare la sospensione o la cessazione dell'accesso al servizio.

### **10\. Responsabilità**

Giuribot è fornito "così com'è" senza garanzie esplicite o implicite. Il Titolare non si assume alcuna responsabilità per danni diretti o indiretti derivanti dall'uso del chatbot, inclusi errori, interruzioni del servizio, o informazioni imprecise o incomplete.

Il Titolare non garantisce che le risposte fornite dal chatbot siano sempre accurate, aggiornate o applicabili al caso specifico dell'utente. L'utente utilizza il chatbot a proprio rischio e pericolo.

Utilizzando i Servizi, l'utente dichiara e garantisce che: (1) tutte le informazioni di registrazione fornite dall'utente saranno vere, accurate, aggiornate e complete; (2) l'utente manterrà l'accuratezza di tali informazioni e aggiornerà tempestivamente tali informazioni di registrazione, se necessario; (3) l'utente ha la capacità giuridica e accetta di rispettare i presenti Termini legali; (4) l'utente non ha meno di 18 anni;  (6) l’utente non accederà ai Servizi con mezzi automatizzati o non umani, tramite bot, script o altro; (7) l’utente non accederà ai Servizi per scopi illegali o non autorizzati e non violerà alcuna legge o regolamento.

### **11\. Registrazione utente**

All'utente potrebbe essere richiesto di registrarsi per utilizzare i Servizi. L'utente accetta di mantenere riservata la propria password e sarà responsabile di ogni utilizzo del proprio account e della propria password. Ci riserviamo il diritto di rimuovere, reclamare o modificare un nome utente selezionato dall'utente se riteniamo, a nostra esclusiva discrezione, che tale nome utente sia inappropriato, osceno o altrimenti discutibile.

### **12\. Acquisto e pagamenti**

Accettiamo le seguenti forme di pagamento:  
\- Visa  
\- Mastercard  
\- American Express

L'utente accetta di fornire informazioni aggiornate, complete e accurate sull'acquisto e sull'account per tutti gli acquisti effettuati tramite i Servizi e di aggiornare tempestivamente le informazioni relative all'account e al pagamento, compresi l'indirizzo e-mail, il metodo di pagamento e la data di scadenza della carta di pagamento, in modo che possiamo completare le vostre transazioni e contattarvi se necessario. Al prezzo degli acquisti verrà aggiunta l'imposta sulle vendite. Il prezzo potrà essere cambiato in qualsiasi momento. Tutti i pagamenti saranno effettuati in euro.  
L'utente accetta di pagare tutte le spese ai prezzi in vigore in quel momento per i suoi acquisti e ci autorizza ad addebitare al fornitore di pagamento scelto tali importi al momento dell'ordine. I pagamenti sono elaborati tramite Stripe e sono soggetti ai termini e alle condizioni del servizio Stripe, che l’utente accetta di rispettare. Ci riserviamo il diritto di correggere eventuali errori o sbagli nei prezzi, anche se abbiamo già richiesto o ricevuto il pagamento.

Possiamo, a nostra esclusiva discrezione, limitare o annullare le quantità acquistate per persona, per famiglia o per ordine. Tali limitazioni possono includere ordini effettuati da o con lo stesso account cliente, lo stesso metodo di pagamento e/o ordini che utilizzano lo stesso indirizzo di fatturazione o di spedizione. Ci riserviamo il diritto di limitare o proibire gli ordini che, a nostro insindacabile giudizio, sembrano essere stati effettuati da commercianti, rivenditori o distributori.

### **13\. Recesso**

Tutti gli acquisti non sono rimborsabili. È possibile annullare l'abbonamento in qualsiasi momento accedendo al proprio account. La cancellazione avrà effetto alla fine del periodo di pagamento in corso.

Se non siete soddisfatti dei nostri Servizi, inviateci un'e-mail all'indirizzo info@giuribot.com

### **14.Modifiche e interruzioni**

Ci riserviamo il diritto di cambiare, modificare o rimuovere i contenuti dei Servizi in qualsiasi momento o per qualsiasi motivo, a nostra esclusiva discrezione e senza preavviso. Tuttavia, non abbiamo l'obbligo di aggiornare alcuna informazione sui nostri Servizi. Non saremo responsabili nei confronti dell'utente o di terzi per qualsiasi modifica, cambiamento di prezzo, sospensione o interruzione dei Servizi.

Non possiamo garantire che i Servizi siano sempre disponibili. Potremmo riscontrare problemi hardware, software o di altro tipo o dover eseguire la manutenzione dei Servizi, con conseguenti interruzioni, ritardi o errori. Ci riserviamo il diritto di cambiare, rivedere, aggiornare, sospendere, interrompere o modificare in altro modo i Servizi in qualsiasi momento o per qualsiasi motivo senza preavviso. L'utente accetta di non essere in alcun modo responsabile di eventuali perdite, danni o disagi causati dall'impossibilità di accedere o utilizzare i Servizi durante qualsiasi periodo di inattività o interruzione dei Servizi. Nulla di quanto contenuto nei presenti Termini legali potrà essere interpretato come un obbligo da parte nostra a mantenere e supportare i Servizi o a fornire correzioni, aggiornamenti o release in relazione ad essi.

### **15.Limitazione di responsabilità**

IN NESSUN CASO LA SOCIETA’ O I RELATIVI I DIRETTORI, DIPENDENTI, COLLABORATORI O AGENTI SARANNO RESPONSABILI NEI CONFRONTI DELL'UTENTE O DI TERZI PER DANNI DIRETTI, INDIRETTI, CONSEGUENTI, ESEMPLARI, INCIDENTALI, SPECIALI O PUNITIVI, COMPRESI PERDITA DI PROFITTO, MANCATO GUADAGNO, PERDITA DI DATI O ALTRI DANNI DERIVANTI DALL'USO DEI SERVIZI DA PARTE DELL'UTENTE, ANCHE SE SIA STATO DATO PREAVVISO DELLA POSSIBILITÀ DI TALI DANNI. NONOSTANTE QUALSIASI DISPOSIZIONE CONTRARIA CONTENUTA NEL PRESENTE DOCUMENTO, LA NOSTRA RESPONSABILITÀ NEI CONFRONTI DELL'UTENTE, PER QUALSIASI CAUSA E INDIPENDENTEMENTE DALLA FORMA DELL'AZIONE, SARÀ SEMPRE LIMITATA ALL'IMPORTO EVENTUALMENTE PAGATO DALL'UTENTE DURANTE IL PERIODO DI UN MESE PRECEDENTE L'INSORGERE DELLA CAUSA DI AZIONE.  

### **16\. Indennizzo**

L'utente accetta di difendere, indennizzare e tenere indenne la Società, comprese le sue consociate e affiliate e tutti i suoi rispettivi funzionari, agenti, partner e dipendenti, da e contro qualsiasi perdita, danno, responsabilità, reclamo o richiesta, comprese le ragionevoli spese legali, avanzate da terzi a causa di o derivanti da: (1) i Contributi dell'utente; (2) l'uso dei Servizi; (3) la violazione dei presenti Termini legali; (4) qualsiasi violazione delle dichiarazioni e garanzie dell'utente stabilite nei presenti Termini legali; (5) la violazione da parte dell'utente dei diritti di terzi, inclusi, a titolo esemplificativo, i diritti di proprietà intellettuale; o (6) qualsiasi atto dannoso palese nei confronti di qualsiasi altro utente dei Servizi con cui l'utente si è collegato tramite i Servizi. Nonostante quanto sopra, ci riserviamo il diritto, a spese dell'utente, di assumere la difesa e il controllo esclusivi di qualsiasi questione per la quale l'utente è tenuto a indennizzare, e l'utente accetta di collaborare, a sue spese, con la nostra difesa di tali reclami. Ci impegneremo a notificare all'utente qualsiasi reclamo, azione o procedimento soggetto a tale indennizzo non appena ne verrà a conoscenza.

### **17\. Legge Applicabile e Foro Competente**

Questi Termini e Condizioni d'Uso sono regolati dalla legge italiana. Per qualsiasi controversia relativa all'interpretazione o all'esecuzione di questi termini, sarà competente in via esclusiva il Tribunale di Roma.

### **18\. Proprietà intellettuale**

Siamo proprietari o licenziatari di tutti i diritti di proprietà intellettuale dei nostri Servizi, compresi tutti i codici sorgente, i database, le funzionalità, il software, il design del sito web, l'audio, il video, il testo, le fotografie e la grafica dei Servizi, nonché i marchi di fabbrica, i marchi di servizio e i loghi in essi contenuti.  
Nessuna parte dei Servizi e nessun Contenuto o Marchio può essere copiato, riprodotto, aggregato, ripubblicato, caricato, pubblicato, mostrato pubblicamente, codificato, tradotto, trasmesso, distribuito, venduto, concesso in licenza o sfruttato in altro modo per qualsiasi scopo commerciale, senza la nostra espressa autorizzazione scritta.

### **19\. Accettazione Finale**

Utilizzando Giuribot, l'utente conferma di aver letto, compreso e accettato i presenti Termini e Condizioni d'Uso e la Privacy Policy. Se non si accettano tali condizioni, si invita a cessare immediatamente l'uso del chatbot.
    `;
