import { Box, ButtonBase, ButtonProps } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

interface AddFilesProps extends ButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const AddFilesButton = ({ onClick, ...props }: AddFilesProps) => {
  return (
    <ButtonBase
      {...props}
      onClick={onClick}
      sx={{
        borderRadius: 45.0,
      }}
    >
      <Box
        sx={{
          borderRadius: 45.0,
          width: 45.0,
          height: 45.0,
          border: '1px solid rgb(180, 180, 180)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <AddIcon
          sx={{
            color: 'rgb(100, 100, 100)',
          }}
        />
      </Box>
    </ButtonBase>
  );
};

export default AddFilesButton;
