import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  runTransaction,
  setDoc,
} from 'firebase/firestore';
import { app } from '../services/firebaseSetUp';
import { ChatHistory } from '../models/chat_history.model';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { httpRequest } from './base';
import { checkQuota } from './key.api';
import { User, UserRegistration } from '../models/user.model';

const db = getFirestore(app);
const auth = getAuth(app);

export const loginWithFirebaseEmailAndPasswordApi = async (
  email: string,
  password: string
) => {
  return signInWithEmailAndPassword(auth, email, password);
};

export const registerUserApi = (userData: UserRegistration) => {
  return httpRequest({
    path: '/users/register',
    method: 'POST',
    data: userData,
  });
};

export const googleAuthApi = () => {
  return httpRequest({
    path: '/users/google-auth',
    method: 'POST',
  });
};

export const getMeApi = () => {
  return httpRequest({
    path: '/users/me',
    method: 'GET',
  });
};

export const checkQuotaApi = async (
  field: 'documentsQuota' | 'searchQuota' | 'summaryQuota'
) => {
  if (auth.currentUser == null) return -1;
  const userDocRef = doc(db, 'users', auth.currentUser!.uid);
  const userDoc = await getDoc(userDocRef);

  if (userDoc.exists()) {
    const userData = userDoc.data();
    return userData[field];
  } else {
    throw new Error('User document does not exist');
  }
};

export const fetchUserIPApi = async () => {
  try {
    const response = await fetch('https://api.ipify.org?format=json');
    const data = await response.json();
    return data.ip; // Returns the IP address
  } catch (error) {
    console.error('Error fetching IP:', error);
    return null;
  }
};

export const checkQuotaUnauthenticatedApi = async (
  field: 'documentsQuota' | 'searchQuota' | 'summaryQuota'
) => {
  const ip = await fetchUserIPApi();
  const userDocRef = doc(db, 'unauthenticatedUsers', ip);
  const userDoc = await getDoc(userDocRef);

  if (userDoc.exists()) {
    const userData = userDoc.data();
    return userData[field];
  } else {
    createUnauthenticatedUser(ip, 2, 10, 10);
    return -1;
  }
};
export const decreaseQuotaApi = async (
  field: 'documentsQuota' | 'searchQuota' | 'summaryQuota',
  step: number
) => {
  return new Promise<void>(async (resolve, reject) => {
    try {
      if (auth.currentUser == null) {
        resolve();
      }
      const userDocRef = doc(db, 'users', auth.currentUser!.uid);

      await runTransaction(db, async (transaction) => {
        const userDoc = await transaction.get(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          const newQuota = Math.max(0, userData[field] - step);

          transaction.update(userDocRef, { [field]: newQuota });
        } else {
          throw new Error('User document does not exist');
        }
      });

      resolve();
    } catch (error) {
      reject(error);
    }
  });
};

export const createUnauthenticatedUser = async (
  ip: string,
  searchQuota: number,
  documentsQuota: number,
  summaryQuota: number
) => {
  const userDocRef = doc(db, 'unauthenticatedUsers', ip);

  const userDoc = await getDoc(userDocRef);
  if (userDoc.exists()) {
    return userDoc.data();
  }

  await setDoc(userDocRef, {
    ip,
    searchQuota,
    documentsQuota,
    summaryQuota,
    createdAt: new Date(),
    updatedAt: new Date(),
  });

  return (await getDoc(userDocRef)).data();
};
