import { Box, List, styled } from '@mui/material';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import SpecialTextField from './components/SpecialTextField';
import Typography from '../Landing/components/Typography';
import ConversationsTimelineDrawer from './components/ConversationsDrawer/ConversationsTimelineDrawer';
import { Message } from '../../models/message.model';
import { ChatBubble } from '../../components/ChatBubble/ChatBubble';
import { useAIResponse } from '../../hooks/useAIResponse';

const AdvancedChat = () => {
  {
    /* Declarations of states */
  }

  const [isChatInitialized, setIsChatInitialized] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [chatBlocked, setChatBlocked] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const { sendPrompt } = useAIResponse();

  const messagesContainerRef = useRef<HTMLDivElement | null>(null);

  const canSendPrompt = useRef(true);

  const handleSendMessage = (
    message: string,
    search: boolean,
    reason: boolean
  ) => {
    if (chatBlocked) return;

    setIsChatInitialized(true);
    canSendPrompt.current = true;

    const newMessage = { content: message, role: 'user' } as Message;
    setMessages((prevMessages) => [...prevMessages, newMessage]);
  };

  const makeAIPrompt = () => {
    let fullMessage = '';

    setChatBlocked(true);

    sendPrompt(
      messages,
      (chunk) => {
        fullMessage += chunk;

        canSendPrompt.current = false;

        setMessages((prevMessages) => {
          const newMessages = [...prevMessages];
          newMessages[messages.length] = {
            content: String(fullMessage),
            role: 'system',
          };

          return newMessages;
        });

        scrollToBottom();
      },
      (full) => {
        setChatBlocked(false);
        scrollToBottom();
      },
      (error) => {
        console.log('Error:', error);
      }
    );
  };

  {
    /* This method will ensure the chat scroll is always on bottom */
  }
  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (!canSendPrompt.current || messages.length == 0) return;
    makeAIPrompt();
  }, [messages]);

  useLayoutEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const ScrollableBox = styled(Box)({
    maxHeight: 'calc(100vh - 250px)', // Set your desired height
    overflowY: 'auto',

    '&::-webkit-scrollbar': {
      width: '8px',
      display: 'none',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '4px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  });

  const messagesBubbles = useMemo(() => {
    return (
      <ScrollableBox ref={messagesContainerRef}>
        {messages.map((message, index) => (
          <ChatBubble
            key={index}
            content={message?.content}
            isADocument={false}
            role={message?.role}
            onVisualize={() => {}}
          />
        ))}
      </ScrollableBox>
    );
  }, [messages]);

  useEffect(() => {
    const container = messagesContainerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  }, [messages]);

  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      {screenWidth > 800 && <ConversationsTimelineDrawer />}

      {/* Chat Container*/}
      <Box
        sx={{
          width: 'calc(100vw - 300px)',
          display: 'flex',
          flexDirection: 'column',
          height: 'calc(100vh - 70px)',
          justifyContent: isChatInitialized ? 'flex-end' : 'center',
          alignItems: 'center',
          flex: 1,
        }}
      >
        {isChatInitialized && (
          <Box
            sx={{
              flex: 1,
              flexDirection: 'column',
              display: 'flex',
              justifyContent: 'flex-start',
              marginTop: 2,

              width: '100%',
            }}
          >
            {messagesBubbles}
          </Box>
        )}
        {!isChatInitialized && (
          <Typography
            sx={{
              textAlign: 'center',
              fontSize: 30,
              fontWeight: 600,
              color: 'rgb(100, 100, 100)',
              marginBottom: 3.0,
            }}
          >
            Con cosa legale posso essere di auto?
          </Typography>
        )}
        <SpecialTextField
          sx={{
            marginBottom: isChatInitialized ? 2.0 : 0.0,
          }}
          onSend={handleSendMessage}
        />
      </Box>
    </Box>
  );
};

export default AdvancedChat;
