import { httpRequest } from './base';
import {
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
} from 'firebase/firestore';
import { app } from '../services/firebaseSetUp';
import { getDoc, doc } from 'firebase/firestore';
import { runTransaction } from 'firebase/firestore';
import { collection, onSnapshot } from 'firebase/firestore';
import { AISearchCompositionModel } from '../models/search';
import { getAuth } from 'firebase/auth';
import { fetchUserIPApi } from './users';

const db = getFirestore(app);
const auth = getAuth(app);

interface QueryData {
  query: string;
  sede?: string;
  tipo?: string;
  year?: string;
  numero?: string;
  page?: number;
  items_per_page?: number;
}

export const getLatestDeepSearchHistoryUnauthenticatedApi: () => Promise<
  AISearchCompositionModel[] | undefined
> = async () => {
  const ip = await fetchUserIPApi();
  const deepSearchRef = collection(
    db,
    'unauthenticatedUsers',
    ip,
    'deepSearch'
  );
  const deepSearchQuery = query(
    deepSearchRef,
    orderBy('timestamp', 'desc'),
    limit(5)
  );
  const querySnapshot = await getDocs(deepSearchQuery);
  const history = querySnapshot.docs.map(
    (doc) => doc.data()['response'] as AISearchCompositionModel
  );
  return history;
};

export const getLatestDeepSearchHistoryApi: () => Promise<
  AISearchCompositionModel[] | undefined
> = async () => {
  if (auth.currentUser?.uid) {
    const deepSearchRef = collection(
      db,
      'users',
      auth.currentUser.uid,
      'deepSearch'
    );

    const deepSearchQuery = query(
      deepSearchRef,
      orderBy('timestamp', 'desc'),
      limit(5)
    );
    const querySnapshot = await getDocs(deepSearchQuery);
    const history = querySnapshot.docs.map(
      (doc) => doc.data()['response'] as AISearchCompositionModel
    );

    return history;
  } else {
    console.error('User is not authenticated');
  }
};

export const vectorSearchApi = (data: QueryData) => {
  return httpRequest({
    url: `${process.env.REACT_APP_SERVER_SEARCH_URL}/hybrid-search`,
    data,
    method: 'POST',
  });
};

export const aiSearchApi = (data: QueryData) => {
  return httpRequest({
    url: `${process.env.REACT_APP_SERVER_SEARCH_URL}/ai-search`,
    data,
    method: 'POST',
  });
};

export const aiSearchUnauthenticatedApi = (data: QueryData, ip: string) => {
  return httpRequest({
    url: `${process.env.REACT_APP_SERVER_SEARCH_URL}/ai-search-anonymous`,
    data: { ...data, ip },
    method: 'POST',
  });
};

export const textSearchApi = (data: QueryData) => {
  return httpRequest({
    url: `${process.env.REACT_APP_SERVER_SEARCH_URL}/text-search`,
    data,
    method: 'POST',
  });
};

export const ragSearchApi = (prompt: string) => {
  return httpRequest({
    url: `http://127.0.0.1:5050/api/retrieve-info`,
    data: { prompt },
    method: 'POST',
  });
};
