import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  List,
  ListItem,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';

import { CircleOutlined, KeyboardArrowRight } from '@mui/icons-material';
import { extendedTheme } from '../../themes/extendedTheme';
import { lecceTheme } from '../../themes/lecce';

// Define styles using makeStyles hook

interface PricingCardProps {
  title: string;
  price: string;
  previousPrice?: string;
  label: string;
  features: Array<string>;
  description: string;
  buttonText: string;
  onClick: Function;
  headerColor?: string;
  frequency?: string;
  disableButton?: boolean;
  isSelected?: boolean;
}

export const PricingCard: React.FC<PricingCardProps> = ({
  title,
  price,
  features,
  description,
  buttonText,
  label,
  headerColor,
  onClick,
  previousPrice,
  frequency,
  disableButton,
  isSelected = false,
}) => {
  const pimaryColor = lecceTheme.palette.warning.light;
  return (
    <Card
      elevation={0}
      sx={{
        borderTopRightRadius: isSelected ? 0 : 5,
        borderTopLeftRadius: isSelected ? 0 : 5,
        minHeight: 460,
        border: !isSelected
          ? '1.5px solid #e0e0e0'
          : `3px solid ${pimaryColor}`,
      }}
    >
      <Box
        display={'flex'}
        flexDirection={'column'}
        sx={{
          height: '100%',
        }}
      >
        <Box bgcolor={'white'}>
          <CardContent>
            <Stack spacing={1} alignItems={'center'}>
              {label !== '' ? (
                <Chip
                  label={label}
                  sx={{
                    backgroundColor: 'white',
                    opacity: 0.7,
                  }}
                />
              ) : null}

              <Typography
                fontWeight={'bold'}
                fontSize={25}
                sx={{
                  color: 'black',
                }}
              >
                {title}
              </Typography>
            </Stack>
          </CardContent>
          <Divider />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
          }}
        >
          <CardContent>
            <List sx={{ mx: 'calc(-1 * var(--ListItem-paddingX))' }}>
              {features.map((e, i) => (
                <ListItem key={i}>
                  <Stack direction={'row'} spacing={1} alignItems={'center'}>
                    <CircleOutlined
                      fontSize="small"
                      sx={{
                        fontSize: '0.6rem',
                      }}
                    />

                    <Typography color={'InfoText'} fontSize={'1rem'}>
                      {e}
                    </Typography>
                  </Stack>
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Box>
        <Box>
          <Divider />
          <CardContent>
            <Stack
              direction={'row'}
              justifyContent={'space-between'}
              alignItems={'flex-end'}
            >
              <Typography sx={{ mr: 'auto' }}>
                <Stack direction={'row'} spacing={1} alignItems={'baseline'}>
                  <Box>
                    {previousPrice ? (
                      <Typography
                        sx={{
                          textDecoration: 'line-through',
                          fontSize: '1.2rem',
                        }}
                        fontWeight={500}
                      >
                        {previousPrice} €
                      </Typography>
                    ) : null}
                    <Typography fontSize={'2rem'} fontWeight={'bold'}>
                      {price} €
                    </Typography>
                  </Box>
                </Stack>
                {frequency ? (
                  <Typography fontSize="sm">/ {frequency}</Typography>
                ) : null}
              </Typography>
              {buttonText !== '' && !isSelected ? (
                disableButton === true ? null : (
                  <Button
                    variant="contained"
                    disableElevation
                    endIcon={<KeyboardArrowRight />}
                    disableRipple
                    onClick={() => {
                      if (onClick) {
                        onClick();
                      }
                    }}
                  >
                    {buttonText}
                  </Button>
                )
              ) : null}
            </Stack>
          </CardContent>
        </Box>
      </Box>
    </Card>
  );
};
