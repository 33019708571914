import Typography from '../components/Typography';
import ProductHeroLayout from './ProductHeroLayout';
import {
  Box,
  Button,
  Chip,
  Container,
  OutlinedInput,
  Stack,
  TextField,
} from '@mui/material';
import { extendedTheme } from '../../../themes/extendedTheme';
import { useTranslation } from 'react-i18next';

import SpecialSearchBar from '../components/SpecialSearchBar';
import { useNavigate } from 'react-router-dom';
import { ArrowForward, Search, Send } from '@mui/icons-material';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack as MuiStack,
} from '@mui/material';
import { ChangeEvent, useState } from 'react';

//import testVideo from "../../assets/vi";

interface ProductHeroProps {
  unauthenticatedLogin: (search: string) => void;
}

export default function ProductHero(props: ProductHeroProps) {
  const { t, i18n } = useTranslation();

  const primaryColor = extendedTheme.colorSchemes.light!.palette.primary.main;

  const [search, setSearch] = useState('');

  const navigate = useNavigate();

  function handleSearchChange(
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void {
    setSearch(event.target.value);
    console.log(`Search Value assigned: ${event.target.value}`);
  }

  const goToSearch = () => {
    props.unauthenticatedLogin(search);
  };

  // Add this inside your component, before the return statement
  const [openAuthDialog, setOpenAuthDialog] = useState(false);

  const handleOpenAuthDialog = () => {
    setOpenAuthDialog(true);
  };

  const handleCloseAuthDialog = () => {
    setOpenAuthDialog(false);
  };

  const handleContinueAsGuest = () => {
    handleCloseAuthDialog();
    goToSearch(); // Proceed with original search function
  };

  const handleLogin = () => {
    // Add login navigation logic here
    navigate('/login');
  };

  const handleRegister = () => {
    // Add register navigation logic here
    navigate('/register');
  };

  return (
    <ProductHeroLayout
      sxBackground={{
        backgroundColor: 'white',
      }}
    >
      <Dialog open={openAuthDialog} onClose={handleCloseAuthDialog}>
        <DialogTitle>Accedi o Registrati</DialogTitle>
        <DialogContent>
          <MuiStack spacing={2} sx={{ mt: 2, minWidth: 300 }}>
            <Button
              fullWidth
              variant="contained"
              disableElevation
              onClick={handleRegister}
            >
              Registrati
            </Button>
            <Button
              fullWidth
              variant="outlined"
              disableElevation
              onClick={handleLogin}
              sx={{ mt: 2 }}
            >
              Accedi
            </Button>

            <Button fullWidth variant="text" onClick={handleContinueAsGuest}>
              Continua senza registrazione
            </Button>
          </MuiStack>
        </DialogContent>
      </Dialog>

      <Container
        id="home"
        sx={{
          px: {
            xs: 5,
            sm: 10,
            md: 30,
            lg: 30,
            xl: 30,
          },
        }}
      >
        <Typography
          fontSize={{
            xs: 45,
            sm: 45,
            md: 45,
            lg: 55,
            xl: 55,
          }}
          textAlign={'center'}
          fontWeight={700}
        >
          {t('section_01_title')}
        </Typography>
        <Typography
          color="black"
          align="center"
          variant="h5"
          textAlign={'center'}
          sx={{
            mb: 5,

            mt: { xs: 1, sm: 1 },
          }}
        >
          {t('section_01_subtitle')}
        </Typography>

        <Box sx={{ mb: 4, width: '100%' }}>
          <OutlinedInput
            placeholder="Parole chiave oppure descrive quello che cerchi"
            value={search}
            onChange={handleSearchChange}
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                handleOpenAuthDialog();
              }
            }}
            startAdornment={
              <Stack
                sx={{
                  px: 1,
                }}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Search />
              </Stack>
            }
            size="medium"
            fullWidth
            endAdornment={
              <Button
                variant="contained"
                disableElevation
                endIcon={<ArrowForward fontSize="small" />}
                onClick={handleOpenAuthDialog}
                sx={{
                  color: 'white',
                  px: 3,

                  backgroundColor: 'black',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.8)',
                  },
                }}
              >
                Cerca
              </Button>
            }
            sx={{
              '&.MuiOutlinedInput-root': {
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'black',
                },
              },
            }}
          />
        </Box>

        <Stack direction={'row'} justifyContent={'space-between'} spacing={2}>
          <Box
            sx={{
              backgroundColor: 'rgba(240, 240, 240)',
              borderRadius: '6px',
              border: '1px solid rgba(80, 80, 80, 0.2)',
              padding: 2,
              flex: 1,
            }}
          >
            <Typography
              variant="h6"
              fontWeight={700}
              sx={{
                lineHeight: '1.2',
                minHeight: 50,
                maxHeight: 50,
              }}
            >
              SENTENZA sede di CONSIGIO DI STATO
            </Typography>

            <Box
              sx={{
                p: 2,
                backgroundColor: 'white',
                padding: 2,
                borderRadius: '6px',
                mt: 2,
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: '20px',
                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                  mb: 1,
                }}
              />
              <Box
                sx={{
                  width: '80%',
                  height: '20px',
                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                  mb: 1,
                }}
              />

              <Box
                sx={{
                  width: '85%',
                  height: '20px',
                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                  mb: 1,
                }}
              />

              <Box
                sx={{
                  width: '95%',
                  height: '20px',
                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              backgroundColor: 'rgba(240, 240, 240)',
              borderRadius: '6px',
              border: '1px solid rgba(80, 80, 80, 0.2)',
              padding: 2,
              flex: 1,
            }}
          >
            <Typography
              variant="h6"
              fontWeight={700}
              sx={{
                minHeight: 50,
                maxHeight: 50,
                lineHeight: '1.2',
              }}
            >
              SENTENZA sede di <br />
              ROMA
            </Typography>

            <Box
              sx={{
                p: 2,
                backgroundColor: 'white',
                padding: 2,
                borderRadius: '6px',
                mt: 2,
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: '20px',
                  backgroundColor: 'rgba(0, 0, 0, 0.1)',

                  mb: 1,
                }}
              />
              <Box
                sx={{
                  width: '80%',
                  height: '20px',
                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                  mb: 1,
                }}
              />

              <Box
                sx={{
                  width: '85%',
                  height: '20px',
                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                  mb: 1,
                }}
              />

              <Box
                sx={{
                  width: '95%',
                  height: '20px',
                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                }}
              />
            </Box>
          </Box>
        </Stack>

        {/* <Stack direction={'row'} justifyContent={'center'}>
          <Button
            disableElevation
            size="large"
            variant="contained"
            onClick={() => {
              navigate(`/panel/giustizia-amministrativa?search=${search}`);
            }}
          >
            Inizia la tua ricerca
          </Button>
        </Stack> */}
      </Container>

      <Box mt={2} mb={5} mx={20}></Box>

      {/*<VideoPlayer src={'../../assets/'} />*/}
    </ProductHeroLayout>
  );
}
