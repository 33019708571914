import React, { useEffect, useState } from 'react';
import { Box, Backdrop, CircularProgress } from '@mui/material';
import './i18n/config';

import { LicenseInfo } from '@mui/x-license';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Landing } from './pages/Landing/Landing';
import { useRecoilState } from 'recoil';
import { appAtom, AppState } from './state/app.atom';
import { Panel } from './pages/Panel/Panel';
import { SignUp } from './pages/SignUp/SignUp';
import CustomToast from './components/CustomToast/CustomToast';

import { getAuth, onAuthStateChanged, Unsubscribe } from 'firebase/auth';
import {
  doc,
  DocumentSnapshot,
  getDoc,
  getFirestore,
  onSnapshot,
} from 'firebase/firestore';
import { app } from './services/firebaseSetUp';
import { getMeApi } from './api/users';
import { User } from './models/user.model';
import { Login } from './pages/Login/Login';
import { PrivacyPolicy } from './pages/PrivacyPolicy/PrivacyPolicy';
import { SuccessPayment } from './pages/SuccessPayment/SuccessPayment';
import { FailurePayment } from './pages/FailurePayment/FailurePayment';

const auth = getAuth(app);
const db = getFirestore(app);

LicenseInfo.setLicenseKey(process.env['REACT_APP_MUI_LICENSE_KEY'] as string);

// Define the SentenceModel schema

const settings = ['Esci'];

const router = createBrowserRouter(
  [
    {
      path: '/success',
      element: <SuccessPayment />,
    },
    {
      path: '/cancel',
      element: <FailurePayment />,
    },
    {
      path: '/panel/*',
      element: <Panel />,
    },
    {
      path: '/',
      element: <Landing />,
    },
    {
      path: '/register',
      element: <SignUp />,
    },
    {
      path: '/login',
      element: <Login />,
    },
    {
      path: '/terms-and-conditions',
      element: <PrivacyPolicy />,
    },
  ],
  {
    basename: '/',
    future: {
      v7_startTransition: true,
    },
  }
);

function App() {
  const [appState, setAppState] = useRecoilState(appAtom);

  let authListener: Unsubscribe | undefined = undefined;

  let userListener: Unsubscribe | undefined = undefined;

  useEffect(() => {
    authListener = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const document = doc(db, 'users', user.uid);
        userListener = onSnapshot(document, (d: DocumentSnapshot<any>) => {
          if (d.exists()) {
            const data: User = d.data();
            setAppState((prevState: AppState) => ({
              ...prevState,
              user: {
                ...data,
              },
            }));

            if (data.picture !== '' && data.picture != null) {
              const cachedPicture = localStorage.getItem(
                'profilePictureBase64'
              );
              if (cachedPicture) {
                setAppState((prevState: AppState) => ({
                  ...prevState,
                  user: {
                    ...data,
                    picture: cachedPicture,
                  },
                }));
              } else {
                fetch(data.picture)
                  .then((response) => response.blob())
                  .then((blob) => {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                      const base64data = reader.result as string;
                      const image = new Image();
                      image.src = base64data;
                      image.onload = () => {
                        const canvas = window.document.createElement('canvas');
                        canvas.width = image.width;
                        canvas.height = image.height;
                        const ctx = canvas.getContext('2d');
                        ctx?.drawImage(image, 0, 0);
                        const imageDataUrl = canvas.toDataURL('image/png');
                        localStorage.setItem(
                          'profilePictureBase64',
                          imageDataUrl
                        );
                        setAppState((prevState: AppState) => ({
                          ...prevState,
                          user: {
                            ...data,
                            picture: imageDataUrl,
                          },
                          unauthenticatedLogin: false,
                          upgradePanel: false,
                        }));
                      };
                    };
                    reader.readAsDataURL(blob);
                  });
              }
            }

            if (data.plan) {
              const document = doc(
                db,
                'products',
                data.plan.items[0].product_id
              );
              getDoc(document).then((data) => {
                const plan = data.data();
                setAppState((prevState: AppState) => ({
                  ...prevState,
                  plan: {
                    name: plan?.name,
                    price: plan?.price,
                    quota: plan?.quota,
                  },
                }));
              });
            }
          }
        });

        if (
          ![
            '/terms-and-conditions',
            '/panel/giustizia-amministrativa',
            '/success',
            '/cancel',
          ].some((path) => router.window?.location.href.includes(path))
        ) {
          router.navigate('/panel/home', { replace: true });
        }
      } else {
        if (localStorage.getItem('profilePictureBase64')) {
          localStorage.removeItem('profilePictureBase64');
        }
        userListener && userListener();

        if (
          !['/terms-and-conditions', '/success', '/cancel'].some((path) =>
            router.window?.location.href.includes(path)
          )
        ) {
          router.navigate('/', { replace: true });
        }
        setAppState((prevState) => ({
          ...prevState,
          user: null,
          plan: null,
        }));
      }
    });

    return () => {
      authListener && authListener();
    };
  }, []);

  return (
    <Box
      sx={{
        height: '100vh',
        overflow: 'auto',
        overscrollBehavior: 'none',
      }}
    >
      <CustomToast />
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme: any) => theme.zIndex.drawer + 99999,
        }}
        open={appState.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <RouterProvider router={router} />
    </Box>
  );
}

export default App;
