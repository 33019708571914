import { Box, ButtonBase, ButtonProps } from '@mui/material';
import VerticalSplitRoundedIcon from '@mui/icons-material/VerticalSplitRounded';

interface OpenCloseDrawerToggleButtonProps extends ButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const OpenCloseDrawerToggleButton = ({
  onClick,
  ...props
}: OpenCloseDrawerToggleButtonProps) => {
  return (
    <ButtonBase
      {...props}
      onClick={onClick}
      sx={{
        borderRadius: 45.0,
      }}
    >
      <Box
        sx={{
          borderRadius: 45.0,
          width: 45.0,
          height: 45.0,

          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <VerticalSplitRoundedIcon
          sx={{
            color: 'rgb(100, 100, 100)',
          }}
        />
      </Box>
    </ButtonBase>
  );
};

export default OpenCloseDrawerToggleButton;
