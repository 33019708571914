import {
  Box,
  Container,
  Drawer,
  IconButton,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Close } from '@mui/icons-material';
import { PricingCard } from '../../components/PricingCard/PricingCard';
import { useRecoilState } from 'recoil';
import { appAtom } from '../../state/app.atom';
import { app } from '../../services';
import { lecceTheme } from '../../themes/lecce';

interface PlansViewerProps {
  open: boolean;
  onClose: Function;
  selectPlan: Function;
  currentPlan?: string;
}

export function PlansViewer({
  open,
  onClose,
  selectPlan,
  currentPlan,
}: PlansViewerProps) {
  const [appState, setAppState] = useRecoilState(appAtom);
  const [yearly, setYearly] = useState<boolean>(false);

  const primaryColor = lecceTheme.palette.warning.light;

  return (
    <Drawer
      open={open}
      onClose={() => {
        onClose();
      }}
      sx={{
        width: '100vw',
        flexShrink: 0,

        '& .MuiDrawer-paper': {
          width: '100vw',
          boxSizing: 'border-box',
          backgroundColor: 'rgb(245, 245, 245)',
          p: { xs: 3, sm: 3, md: 4, lg: 5, xl: 5 },
        },
      }}
    >
      <div
        style={{
          position: 'relative',
        }}
      >
        <IconButton
          aria-label="close"
          onClick={() => {
            onClose();
          }}
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            zIndex: 99,
            //  transform: 'translate(40%, -40%)',
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </div>
      <Typography variant="h4" align="center" component="h2">
        Listino Prezzi
      </Typography>

      <Stack
        sx={{
          mt: 5,
        }}
        direction={'row'}
        justifyContent={'center'}
        alignItems={'center'}
        spacing={1}
      >
        <Typography>{'Mensile'}</Typography>
        <Switch
          checked={yearly}
          color="secondary"
          onChange={(el) => {
            setYearly(el.target.checked as boolean);
          }}
        />
        <Typography>{'Annuale'}</Typography>
      </Stack>

      {yearly ? (
        <Typography mt={2} align="center" variant="h5" color={'grey'}>
          15% di sconto
        </Typography>
      ) : null}

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
          gap: 3,
          mt: 5,
          mx: 'auto',
          maxWidth: '100%',
        }}
      >
        <Box>
          {appState.plan?.name == 'Basic' ? (
            <Box
              sx={{
                height: '35px',
                backgroundColor: primaryColor,
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                direction: 'column',
              }}
            >
              <Typography color={'white'} fontWeight={600}>
                Attuale
              </Typography>
            </Box>
          ) : (
            <Box height={'35px'} />
          )}
          <PricingCard
            title={'Base'}
            features={[
              '50 richerche di sentenze / al mese',
              'Estrazione strutturata di 200 documenti / al mese',
              // 'Chatta con 30 documenti / al mese',
              'Supporto base',
            ]}
            description=""
            price={yearly ? '50.00' : '5.00'}
            label={'PIÙ ECONOMICO'}
            buttonText={
              currentPlan == 'basic-yearly' || currentPlan == 'basic-monthly'
                ? ''
                : 'SCEGLI'
            }
            onClick={() => {
              selectPlan('basic' + '-' + (yearly ? 'yearly' : 'monthly'));
              onClose();
            }}
            frequency={yearly ? 'anno' : 'mese'}
            isSelected={appState.plan?.name == 'Basic'}
          />
        </Box>
        <Box>
          {appState.plan?.name == 'Advanced' ? (
            <Box
              sx={{
                height: '35px',
                backgroundColor: primaryColor,
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                direction: 'column',
              }}
            >
              <Typography color={'white'} fontWeight={600}>
                Attuale
              </Typography>
            </Box>
          ) : (
            <Box height={'35px'} />
          )}
          <PricingCard
            title={'Avanzato'}
            features={[
              '500 richerche di sentenze / al mese',
              'Estrazione strutturata di 1000 documenti / al mese',
              // 'Chatta con 100 documenti / al mese',
              'Supporto avanzato',
            ]}
            description=""
            price={yearly ? '99.00' : '10.00'}
            label={'PIÙ COMUNE'}
            headerColor="primary.main"
            buttonText={
              currentPlan == 'advanced-yearly' ||
              currentPlan == 'advanced-monthly'
                ? ''
                : 'SCEGLI'
            }
            onClick={() => {
              selectPlan('advanced' + '-' + (yearly ? 'yearly' : 'monthly'));
              onClose();
            }}
            frequency={yearly ? 'anno' : 'mese'}
            isSelected={appState.plan?.name == 'Advanced'}
          />
        </Box>

        <Box>
          {appState.plan?.name == 'Pro' ? (
            <Box
              sx={{
                height: '35px',
                backgroundColor: primaryColor,
                borderTopLeftRadius: '10px',
                borderTopRightRadius: '10px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                direction: 'column',
              }}
            >
              <Typography color={'white'} fontWeight={600}>
                Attuale
              </Typography>
            </Box>
          ) : (
            <Box height={'35px'} />
          )}
          <PricingCard
            title={'Pro'}
            features={[
              '2000 richerche di sentenze / al mese',
              'Estrazione strutturata di 2500 documenti / al mese',
              // 'Chatta con 250 documenti / al mese',
              'Supporto pro',
            ]}
            description=""
            price={yearly ? '255.00' : '25.00'}
            previousPrice={yearly ? '306' : '30.00'}
            label="PER PROFESSIONISTI"
            buttonText={
              currentPlan == 'pro-yearly' || currentPlan == 'pro-monthly'
                ? ''
                : 'SCEGLI'
            }
            onClick={() => {
              selectPlan('pro' + '-' + (yearly ? 'yearly' : 'monthly'));
              onClose();
            }}
            frequency={yearly ? 'anno' : 'mese'}
            isSelected={appState.plan?.name == 'Pro'}
          />
        </Box>
      </Box>
    </Drawer>
  );
}
