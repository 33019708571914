import React from 'react';
import { useRecoilState } from 'recoil';
import { appAtom } from '../../state/app.atom';
import { checkApiKeyExistsApi } from '../../api/key.api';
import { useNavigate } from 'react-router-dom';
import AppAppBar from './views/AppAppBar';
import ProductHero from './views/ProductHero';
import WhySection from './views/WhySection';
import ProductCategories from './views/ProductCategories';
import PricingPage from './views/PricingPage';
import AppFooter from './views/AppFooter';
import FAQSection from './views/FAQSection';
import ProductSmokingHero from './views/ProductSmokingHero';
import { createUnauthenticatedUser } from '../../api/users';
import { toastAtom } from '../../state/toast.atom';

export const Landing = () => {
  const [appState, setAppState] = useRecoilState(appAtom);
  const [toastState, setToastState] = useRecoilState(toastAtom);

  const navigate = useNavigate();

  const fetchUserIP = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      return data.ip; // Returns the IP address
    } catch (error) {
      console.error('Error fetching IP:', error);
      return null;
    }
  };

  const createUnauthenticatedUserFunc = async (ip: string) => {
    try {
      setAppState((state) => ({
        ...state,
        isLoading: true,
      }));

      const newUser = await createUnauthenticatedUser(ip, 2, 10, 10);
      setAppState((state) => ({
        ...state,
        unauthenticatedLogin: true,
        isLoading: false,
      }));
    } catch (error) {
      console.error('Error creating unauthenticated user:', error);
      setAppState((state) => ({
        ...state,
        isLoading: false,
      }));

      setToastState((state) => ({
        ...state,
        open: true,
        message: "Errore durante la creazione dell'ospite",
        severity: 'error',
      }));
    }
  };

  return (
    <React.Fragment>
      <AppAppBar window={window} hideNavigation={false} />
      <ProductHero
        unauthenticatedLogin={(search: string) => {
          fetchUserIP().then(async (ip) => {
            await createUnauthenticatedUserFunc(ip);
            navigate(`/panel/giustizia-amministrativa?search=${search}`);
          });
        }}
      />
      {/* <FAQSection /> */}
      {/* <WhySection />
      <ProductCategories />
      <PricingPage />
      <FAQSection />
      <ProductSmokingHero /> */}
      <AppFooter />
    </React.Fragment>
  );
};
