import { ChatPromptTemplate } from '@langchain/core/prompts';
import { Message } from '../models/message.model';
import { ChatOpenAI } from '@langchain/openai';
import { checkQuotaApi, decreaseQuotaApi } from '../api/users';
import { MessageContent } from '@langchain/core/messages';
import { ragSearchApi } from '../api/search';

export const useAIResponse = () => {
  const sendPrompt = async (
    messages: Message[],
    chunkResponse: (chunk: MessageContent) => void,
    onResponseFinished: (full: string) => void,
    onError: (error: string) => void
  ) => {
    let fullContent = '';

    try {
      const userPrompt = messages[messages.length - 1].content;

      console.log('User Prompt:', userPrompt);

      const responseRag = await ragSearchApi(userPrompt);

      const extraInfo = responseRag.data.extraInfo;

      console.log('Extra Info:', extraInfo);

      const prompt =
        'Sei un chatbot utile. Usa solo i segunti elementi di contesto per rispondere alla domanda. Non inventare nuove informazioni: {extraInfo}';

      console.log('Trying to add:', { content: prompt, role: 'system' });
      const callSentencePrompt = ChatPromptTemplate.fromMessages([
        ...(messages as {
          content: string;
          role: 'user' | 'system';
        }[]),
        {
          content: prompt,
          role: 'system',
          inputVariables: { extractInfo: extraInfo },
        },
      ]);

      console.log('Call Sentence Prompt:', messages);

      const llm = new ChatOpenAI({
        model: 'gpt-4o-mini',
        apiKey: process.env['REACT_APP_OPENAI_API_KEY'],
        temperature: 0,
      });

      const chain = callSentencePrompt.pipe(llm);

      const quota = await checkQuotaApi('summaryQuota');

      if (quota > 0) {
        const result = await chain.stream({ extraInfo });

        await decreaseQuotaApi('summaryQuota', 1);

        for await (const chunk of result) {
          fullContent += chunk.content;
          chunkResponse(chunk.content);
        }
      } else {
        onError('Non hai abbastanza crediti');
      }
    } catch (e) {
      console.error('Error:', e);

      onError(e + '');
    }

    try {
    } catch (e) {
      console.error('Error:', e);

      onError(e + '');
    }

    onResponseFinished(fullContent);
    console.log('Full Content:', fullContent);
  };

  return { sendPrompt };
};
