import { Box, List } from '@mui/material';
import ConversationItem from './ConversationItem';
import OpenCloseDrawerToggleButton from './OpenCloseDrawerToggleButton';
import { useState } from 'react';
import { motion } from 'motion/react';
import Typography from '../../../Landing/components/Typography';

const ConversationsTimelineDrawer = () => {
  const [isOpen, setIsOpen] = useState(true);
  const handleToggleOpenClose = () => {
    setIsOpen(!isOpen);
  };

  const box = {
    width: 250,
    height: 'calc(100vh - 70px)',
    backgroundColor: 'rgb(245, 245, 245)',
    borderRight: '1px solid rgb(200, 200, 200)',
    paddingLeft: isOpen ? 16 : 0,
    paddingRight: isOpen ? 16 : 0,
  };
  return (
    <motion.div
      style={box}
      animate={{ width: isOpen ? 250 : 60 }}
      transition={{ duration: 0.3 }}
    >
      <Box display={'flex'} mb={2} mt={1} mx={!isOpen ? 0.5 : 0}>
        <OpenCloseDrawerToggleButton onClick={handleToggleOpenClose} />
      </Box>

      {isOpen && (
        <List>
          <Typography fontWeight={600} fontSize={12} mb={0.5} mx={1}>
            Ultimi 7 giorni
          </Typography>
          <ConversationItem
            chat={{
              id: 'string',
              title: 'Titolo di prova',
              date: new Date().getMilliseconds(),
              files: [],
              filesPaths: [],
              filesUrls: [],
              messages: [],
            }}
          />

          <ConversationItem
            chat={{
              id: 'string',
              title: 'Altro titolo di prova',
              date: new Date().getMilliseconds(),
              files: [],
              filesPaths: [],
              filesUrls: [],
              messages: [],
            }}
          />
        </List>
      )}
    </motion.div>
  );
};

export default ConversationsTimelineDrawer;
