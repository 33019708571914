import { WarningAmber } from '@mui/icons-material';
import { Box, Button, Collapse, Stack, Typography } from '@mui/material';
import React from 'react';
import Markdown from '../../pages/Landing/components/Markdown';

export const AIMessageContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [open, setOpen] = React.useState(false);
  return (
    <Box
      sx={{
        p: 2,
        backgroundColor: (theme) => theme.palette.primary.light + '33', // Adding transparency to make it very light
      }}
    >
      <Box display={'flex'} marginTop={'auto'} marginBottom={'auto'}>
        <WarningAmber
          sx={{
            width: '16px',
            height: '15px',
            color: 'rgb(130, 130, 130)',
          }}
        />

        <Typography
          ml={0.5}
          color="grey.600"
          fontWeight={'500'}
          fontSize={'12px'}
        >
          Questo contenuto è generato dall'IA e può contenere errori.
        </Typography>
      </Box>

      <Box mt={1}>
        <Collapse in={open} collapsedSize={300}>
          <Markdown>{children}</Markdown>
        </Collapse>

        <Stack direction={'row'} justifyContent={'center'}>
          <Button>
            <Typography
              color="primary"
              fontSize={'12px'}
              onClick={() => setOpen(!open)}
            >
              {open ? 'Nascondi' : 'Mostrare più'}
            </Typography>
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};
