import { Box, BoxProps, Menu, TextField } from '@mui/material';
import AddFilesButton from './AddFilesButton';
import { useState } from 'react';
import IconMenu from './IconMenu';
import ChatIconButton from './ChatIconButton';
import React from 'react';

interface SpecialTextFieldProps extends BoxProps {
  onSend: (message: string, search: boolean, reason: boolean) => void;
}

const SpecialTextField = ({ onSend, ...props }: SpecialTextFieldProps) => {
  const [currentText, setCurrentText] = useState('');
  const [isUsingSearch, setIsUsingSearch] = useState(false);
  const [isUsingReason, setIsUsingReason] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUsingSearchChanged = (state: boolean) => {
    setIsUsingSearch(state);
  };

  const handleUsingReasonChanged = (state: boolean) => {
    setIsUsingReason(state);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (!event.target) return;
      onSend(currentText, isUsingSearch, isUsingReason);
      setCurrentText('');
    }
  };

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentText(event.target.value);
  };

  return (
    <Box
      component="form"
      autoComplete="off"
      sx={{
        borderRadius: 7.0,
        backgroundColor: 'rgb(245, 245, 245)',
        padding: 2,
        border: '1px solid rgb(200, 200, 200)',
        width: {
          xs: 'calc(100% - 80px)',

          lg: 'calc(100% - 250px)',
        },

        mx: {
          xs: 10,
          sm: 10,
          md: 10,
        },
        maxWidth: 700,
        ...props.sx,
      }}
    >
      <TextField
        id="filled-basic"
        placeholder="Message Giuribot"
        multiline={true}
        maxRows={9}
        onKeyDown={handleKeyDown}
        onChange={handleTextChange}
        value={currentText}
        sx={{
          width: '100%',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }}
      />

      <Box
        sx={{
          marginTop: 1,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <AddFilesButton
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        />

        <Box sx={{ marginLeft: 1 }}>
          <ChatIconButton
            state={isUsingSearch}
            onStateChanged={handleUsingSearchChanged}
            buttonType="search"
          >
            Ricerca
          </ChatIconButton>
        </Box>

        <Box sx={{ marginLeft: 1 }}>
          <ChatIconButton
            state={isUsingReason}
            onStateChanged={handleUsingReasonChanged}
            buttonType="reason"
          >
            Ragionamento
          </ChatIconButton>
        </Box>
      </Box>

      <Menu
        id="menu-load-files"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{
          borderRadius: 15.0,
        }}
      >
        <IconMenu />
      </Menu>
    </Box>
  );
};

export default SpecialTextField;
