import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  Grid2,
  IconButton,
  lighten,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { Home } from '../Home/Home';
import { StructuredOutput } from '../StructuredOutput/StructuredOutput';
import { appAtom } from '../../state/app.atom';
import { useRecoilState } from 'recoil';
import { getAuth, signOut } from 'firebase/auth';
import { app } from '../../services/firebaseSetUp';
import logo from '../../assets/giuribot_logo_extractor.png';
import { PlansViewer } from '../Plans/Plans';
import { checkoutApi, updateSubscriptionApi } from '../../api/pricing';
import { toastAtom } from '../../state/toast.atom';
import Footer from '../Footer/Footer';
import { SummaryDocs } from '../SummaryDocs/SummaryDocs';
import { GiustiziaAmministrativa } from '../GiustiziaAmministrativa/GiustiziaAmministrativa';
import { extendedTheme } from '../../themes/extendedTheme';
import AppFooter from '../Landing/views/AppFooter';
import AdvancedChat from '../Chat/AdvancedChat';
import { Stars } from '@mui/icons-material';

const auth = getAuth(app);

export const Panel = () => {
  const [appState, setAppState] = useRecoilState(appAtom);
  const [toastState, setToastState] = useRecoilState(toastAtom);
  const [planOpen, setPlanOpen] = React.useState(false);

  const [planChecked, setPlanChecked] = React.useState(false);

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );

  const navigate = useNavigate();

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const settings = ['Esci'];

  const location = useLocation();

  const parseNameToItalian = (plan: string) => {
    if (plan == 'Basic') {
      return 'Base';
    } else if (plan == 'Advanced') {
      return 'Avanzato';
    } else {
      return 'Pro';
    }
  };

  useEffect(() => {
    if (appState.user) {
      if (appState.user.plan == null && planChecked === false) {
        setPlanChecked(true);
      }
    }
  }, [appState.user]);

  const primaryColor = extendedTheme.colorSchemes.light?.palette.primary.main;

  return (
    <Box>
      <PlansViewer
        selectPlan={(plan: string) => {
          setAppState((state) => ({
            ...state,
            isLoading: true,
          }));

          if (appState.plan !== null) {
            updateSubscriptionApi(plan)
              .then((response) => {
                window.location.href = response.data.url;
                setAppState((state) => ({
                  ...state,
                  isLoading: false,
                }));

                setToastState({
                  open: true,
                  message: 'Piano aggiornato con successo',
                });
              })
              .catch((error) => {
                console.error(error);
                setAppState((state) => ({
                  ...state,
                  isLoading: false,
                }));

                setToastState({
                  open: true,
                  message: "Errore durante l'aggiornamento del piano",
                });
              });
          } else {
            checkoutApi(plan)
              .then((response) => {
                window.location.href = response.data.url;
                setAppState((state) => ({
                  ...state,
                  isLoading: false,
                }));
              })
              .catch((error) => {
                console.error(error);
                setAppState((state) => ({
                  ...state,
                  isLoading: false,
                }));

                setToastState({
                  open: true,
                  message: 'Errore durante il checkout del piano',
                });
              });
          }
        }}
        open={planOpen}
        onClose={() => {
          setPlanOpen(false);
        }}
      />
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          backgroundColor: 'white',
          borderBottom: '1px solid #ccc',
          mb: 0,
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Box>
            <Typography variant="h6" component="div">
              <Box
                component={'img'}
                src={logo}
                height={{
                  xs: 40,
                  sm: 50,
                }}
              />
            </Typography>
          </Box>

          <Box>
            <Stack direction={'row'} spacing={1} justifyContent={'flex-end'}>
              {appState.user ? (
                <>
                  <Button
                    disableElevation
                    variant="contained"
                    color={appState.plan ? 'warning' : 'primary'}
                    endIcon={<Stars />}
                    sx={{
                      backgroundColor: appState.plan
                        ? 'warning.light'
                        : 'primary',
                      '&:hover': {
                        backgroundColor: appState.plan
                          ? 'warning.light'
                          : 'primary',
                      },
                    }}
                    onClick={() => {
                      setPlanOpen(true);
                    }}
                  >
                    {appState.plan
                      ? parseNameToItalian(appState.plan?.name)
                      : 'Scegli un piano'}
                  </Button>
                  <Box>
                    <Tooltip title="Open settings">
                      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                        <Avatar
                          alt={appState.user?.email}
                          src={
                            appState.user?.picture !== ''
                              ? appState.user?.picture
                              : localStorage.getItem('profilePictureBase64') ||
                                undefined
                          }
                        />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      sx={{ mt: '45px' }}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                    >
                      {settings.map((setting) => (
                        <MenuItem
                          key={setting}
                          onClick={() => {
                            handleCloseUserMenu();

                            if (setting === 'Esci') {
                              signOut(auth);
                              setAppState((prevState) => ({
                                ...prevState,
                                isApiKeyValid: false,
                                apiKey: '',
                              }));
                            }
                          }}
                        >
                          <Typography sx={{ textAlign: 'center' }}>
                            {setting}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>
                </>
              ) : (
                <>
                  <Button
                    disableElevation
                    variant="outlined"
                    color="primary"
                    onClick={() => navigate('/login')}
                  >
                    Accedi
                  </Button>
                  <Button
                    disableElevation
                    variant="contained"
                    color="primary"
                    onClick={() => navigate('/register')}
                  >
                    Registrati
                  </Button>
                </>
              )}
            </Stack>
          </Box>
        </Toolbar>
      </AppBar>

      <Routes>
        <Route path="/" element={<Navigate to={'/panel/home'} />} />
        <Route path="/home" element={<Home />} />
        <Route path="/structured-output" element={<StructuredOutput />} />
        {/*<Route path="/chat-docs" element={<SummaryDocs />} />*/}
        <Route path="/chat-docs" element={<AdvancedChat />} />
        <Route
          path="/giustizia-amministrativa"
          element={<GiustiziaAmministrativa />}
        />
      </Routes>

      {appState.upgradePanel && (
        <Box
          sx={(theme) => ({
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: lighten(theme.palette.warning.light, 0.5),
            borderTop: '1px solid #ccc',
            p: 2,
            zIndex: 1000,
          })}
        >
          <Container>
            <Grid2 container justifyContent="space-between" alignItems="center">
              <Grid2 size={8}>
                <Typography variant="body1">
                  Quota esaurita, per favore registrati per scegliere un piano.
                </Typography>
              </Grid2>
              <Grid2 size={4}>
                <Stack
                  direction={'row'}
                  spacing={1}
                  justifyContent={'flex-end'}
                >
                  {appState.user ? (
                    <Button
                      variant="contained"
                      disableElevation
                      sx={{
                        backgroundColor: 'black',
                        color: 'white',
                        '&:hover': {
                          backgroundColor: lighten('rgb(0,0,0)', 0.2),
                        },
                      }}
                      onClick={() => setPlanOpen(true)}
                    >
                      Scegli un piano
                    </Button>
                  ) : (
                    <>
                      <Button
                        disableElevation
                        variant="text"
                        sx={{
                          color: 'black',
                          borderColor: 'black',
                        }}
                        onClick={() => navigate('/login')}
                      >
                        Accedi
                      </Button>
                      <Button
                        disableElevation
                        variant="contained"
                        sx={{
                          backgroundColor: 'black',
                          color: 'white',
                          '&:hover': {
                            backgroundColor: lighten('rgb(0,0,0)', 0.2),
                          },
                        }}
                        onClick={() => navigate('/register')}
                      >
                        Registrati
                      </Button>
                    </>
                  )}
                </Stack>
              </Grid2>
            </Grid2>
          </Container>
        </Box>
      )}

      {!location.pathname.includes('chat-docs') ? <AppFooter /> : null}
    </Box>
  );
};
