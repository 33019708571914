import {
  Box,
  Breadcrumbs,
  Button,
  Chip,
  CircularProgress,
  Container,
  FormControl,
  FormLabel,
  LinearProgress,
  LinearProgressProps,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { ProvvedimentoData } from '../../models/provvedimento.model';
import { Provvedimento } from '../../components/Provvedimento/Provvedimento';
import { ArrowForward } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import {
  aiSearchApi,
  aiSearchUnauthenticatedApi,
  getLatestDeepSearchHistoryApi,
  getLatestDeepSearchHistoryUnauthenticatedApi,
  textSearchApi,
  vectorSearchApi,
} from '../../api/search';
import {
  checkQuotaApi,
  checkQuotaUnauthenticatedApi,
  decreaseQuotaApi,
  fetchUserIPApi,
} from '../../api/users';
import { useRecoilState } from 'recoil';
import { toastAtom } from '../../state/toast.atom';
import { getGeneralParametersApi } from '../../api/parameters.api';
import { useScreenWidth } from '../../hooks/useScreenWidth';
import { AISearchCompositionModel, SentencesModel } from '../../models/search';
import { AIMessageContainer } from '../../components/AIMessage/AIMessageContainer';
import {
  collection,
  doc,
  getFirestore,
  onSnapshot,
  Unsubscribe,
} from 'firebase/firestore';
import { app } from '../../services/firebaseSetUp';
import { getAuth } from 'firebase/auth';
import theme from '../../modules/theme';
import ChatIconButton from '../Chat/components/ChatIconButton';
import { extendedTheme } from '../../themes/extendedTheme';
import { appAtom } from '../../state/app.atom';

const firestore = getFirestore(app);

const auth = getAuth(app);

function LinearProgressWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          sx={{ color: 'text.secondary' }}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

function YearsBar({ years }: { years: any[] }) {
  return (
    <Stack
      direction={'row'}
      spacing={1}
      alignItems={'center'}
      mt={2}
      justifyContent={'space-between'}
      sx={{
        paddingRight: '43px',
      }}
    >
      {years.map((year, index) => {
        const fontSize = index % 5 === 0 ? '1rem' : '0.6rem';
        return (
          <Box key={year}>
            <Box
              sx={{
                height: '25px',
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'flex-end',
                direction: 'column',
              }}
            >
              <Typography fontSize={fontSize}>{year.value}</Typography>
            </Box>
            <Box
              sx={{
                height: '6px',
                marginTop: '0.5px',
                borderRight: '2px solid #ccc',
              }}
            ></Box>
          </Box>
        );
      })}
    </Stack>
  );
}

export const GiustiziaAmministrativa = () => {
  const screenWidth = useScreenWidth();

  const navigate = useNavigate();
  const [toast, setToast] = useRecoilState(toastAtom);
  const provvedimentiTypes = [
    { value: 'DECRETO', label: 'Decreto' },
    { value: 'ORDINANZA', label: 'Ordinanza' },
    { value: 'PARERE', label: 'Parere' },
    { value: 'SENTENZA', label: 'Sentenza' },
    { value: 'ADUNANZA PLENARIA', label: 'Adunanza Plenaria' },
    { value: 'ADUNANZA GENERALE', label: 'Adunanza Generale' },
  ];

  const sedi = [
    { value: 'CONSIGLIO DI STATO', label: 'Consiglio di Stato' },
    { value: 'CONSIGLIO DI GIUSTIZIA AMMINISTRATIVA', label: 'C.G.A.R.S' },
    { value: 'ANCONA', label: 'Ancona' },
    { value: 'AOSTA', label: 'Aosta' },
    { value: 'BARI', label: 'Bari' },
    { value: 'BOLOGNA', label: 'Bologna' },
    { value: 'BOLZANO', label: 'Bolzano' },
    { value: 'BRESCIA', label: 'Brescia' },
    { value: 'CAGLIARI', label: 'Cagliari' },
    { value: 'CAMPOBASSO', label: 'Campobasso' },
    { value: 'CATANIA', label: 'Catania' },
    { value: 'CATANZARO', label: 'Catanzaro' },
    { value: 'FIRENZE', label: 'Firenze' },
    { value: 'GENOVA', label: 'Genova' },
    { value: "L'AQUILA", label: "L'Aquila" },
    { value: 'LATINA', label: 'Latina' },
    { value: 'LECCE', label: 'Lecce' },
    { value: 'MILANO', label: 'Milano' },
    { value: 'NAPOLI', label: 'Napoli' },
    { value: 'PALERMO', label: 'Palermo' },
    { value: 'PARMA', label: 'Parma' },
    { value: 'PERUGIA', label: 'Perugia' },
    { value: 'PESCARA', label: 'Pescara' },
    { value: 'POTENZA', label: 'Potenza' },
    { value: 'REGGIOCALABRIA', label: 'ReggioCalabria' },
    { value: 'ROMA', label: 'Roma' },
    { value: 'SALERNO', label: 'Salerno' },
    { value: 'TORINO', label: 'Torino' },
    { value: 'TRENTO', label: 'Trento' },
    { value: 'TRIESTE', label: 'Trieste' },
    { value: 'VENEZIA', label: 'Venezia' },
  ];

  const anni = [
    { value: 2025, label: '2025' },
    { value: 2024, label: '2024' },
    { value: 2023, label: '2023' },
    { value: 2022, label: '2022' },
    { value: 2021, label: '2021' },
    { value: 2020, label: '2020' },
    { value: 2019, label: '2019' },
    { value: 2018, label: '2018' },
    { value: 2017, label: '2017' },
    { value: 2016, label: '2016' },
    { value: 2015, label: '2015' },
    { value: 2014, label: '2014' },
    { value: 2013, label: '2013' },
    { value: 2012, label: '2012' },
    { value: 2011, label: '2011' },
    { value: 2010, label: '2010' },
    { value: 2009, label: '2009' },
    { value: 2008, label: '2008' },
    { value: 2007, label: '2007' },
    { value: 2006, label: '2006' },
    { value: 2005, label: '2005' },
    { value: 2004, label: '2004' },
    { value: 2003, label: '2003' },
    { value: 2002, label: '2002' },
    { value: 2001, label: '2001' },
    { value: 2000, label: '2000' },
  ];

  const searchTypes = [
    { value: 1, label: 'Ibrido' },
    { value: 2, label: 'Semantico' },
  ];

  const [tipoProvvedimento, setTipoProvvedimento] = useState('');

  const onChangeProvvedimento = (event: SelectChangeEvent<unknown>) => {
    setTipoProvvedimento(event.target.value as string);
  };

  const [sede, setSede] = useState('');

  const onChangeSede = (event: SelectChangeEvent<unknown>) => {
    setSede(event.target.value as string);
  };

  const [year, setYear] = useState('');
  const onChangeYear = (event: SelectChangeEvent<unknown>) => {
    setYear(event.target.value as string);
  };

  const [number, setNumber] = useState('');
  const onChangeNumber = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setNumber(event.target.value);
  };

  const [propmt, setPrompt] = useState('');

  const [pendingLoadingMore, setPendingLoadingMore] = useState(false);

  const [page, setPage] = useState(1);

  const [promptStatic, setPromptStatic] = useState('');
  const onChangePrompt = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPrompt(event.target.value);
  };

  const [searchType, setSearchType] = useState('');

  const [parameters, setParameters] = useState<{
    availableSentencesPercentage: number;
    availableSentencesPercentageAI: number;
    availableYearsText: string[];
    availableYearsAI: string[];
  } | null>(null);
  const onChangeSearch = (event: SelectChangeEvent<unknown>) => {
    setSearchType(event.target.value as string);
  };

  let deepSearchObserver: Unsubscribe | undefined;

  const [deepSearchHistory, setDeepSearchHistory] = useState<
    AISearchCompositionModel[]
  >([]);

  const [provvedimenti, setProvvedimenti] = useState<ProvvedimentoData[]>([]);

  const [pending, setPending] = useState<boolean>(false);

  const [usingAISearch, setUsingAISearch] = useState(true);

  const [aiSearchComposition, setAiSearchComposition] =
    useState<AISearchCompositionModel | null>(null);

  const [aiCompletionInProgress, setAiCompletionInProgress] = useState(false);

  const [nonAuthUsedQuota, setNonAuthUsedQuota] = useState<number>(0);

  const [appState, setAppState] = useRecoilState(appAtom);

  const NON_AUTH_QUOTA = 4;

  const getGeneralParameters = async () => {
    getGeneralParametersApi().then((result) => {
      setParameters(
        result as {
          availableSentencesPercentage: number;
          availableSentencesPercentageAI: number;
          availableYearsText: string[];
          availableYearsAI: string[];
        }
      );
    });
  };

  const getLatestDeepSearchHistory = async () => {
    let history = undefined;
    if (appState.unauthenticatedLogin) {
      history = await getLatestDeepSearchHistoryUnauthenticatedApi();
    } else {
      history = await getLatestDeepSearchHistoryApi();
    }

    if (history === undefined) {
      return;
    }

    if (history) setDeepSearchHistory(history);
  };

  useEffect(() => {
    const checkQuota = async () => {
      let quota;
      if (appState.unauthenticatedLogin) {
        quota = await checkQuotaUnauthenticatedApi('searchQuota');
      } else {
        quota = await checkQuotaApi('searchQuota');
      }

      if (quota === 0) {
        setAppState((prevState) => ({
          ...prevState,
          upgradePanel: true,
        }));
      }
    };

    checkQuota();
  }, [appState.unauthenticatedLogin]);

  useEffect(() => {
    if (aiSearchComposition) {
      //   if (deepSearchObserver) {
      //     deepSearchObserver();
      //     deepSearchObserver = undefined;
      //   }
      console.log(aiSearchComposition);
      localStorage.setItem(
        'aiSearchComposition',
        JSON.stringify(aiSearchComposition)
      );
    }
  }, [aiSearchComposition]);

  const performDeepSearchAI = async () => {
    try {
      if (deepSearchObserver) {
        deepSearchObserver();
        deepSearchObserver = undefined;
      }

      if (String(propmt).trim().length == 0) {
        setToast({
          open: true,
          message: 'Per favore, inserisci un testo per la ricerca',
          autoHideDuration: 6000,
        });
        return;
      }

      setAiSearchComposition(null);

      let quota = await checkQuotaApi('searchQuota');

      if (appState.unauthenticatedLogin) {
        quota = await checkQuotaUnauthenticatedApi('searchQuota');
      }

      // If the quota is -1, we check if the local quota has been consumed
      if (quota == -1 && nonAuthUsedQuota >= NON_AUTH_QUOTA) {
        setToast({
          open: true,
          message: 'Quota esaurita',
          autoHideDuration: 6000,
        });

        setAppState((prevState) => ({
          ...prevState,
          upgradePanel: true,
        }));
        return;
      }

      if (quota > 0 || (quota == -1 && nonAuthUsedQuota < NON_AUTH_QUOTA)) {
        setPending(true);

        setPromptStatic(propmt);

        setProvvedimenti([]);

        let result = undefined;

        if (appState.unauthenticatedLogin) {
          const ip = await fetchUserIPApi();
          result = await aiSearchUnauthenticatedApi(
            {
              query: String(propmt).trim(),
              sede,
              tipo: tipoProvvedimento,
              year: String(year),
              numero: String(number),
            },
            ip
          );
        } else {
          result = await aiSearchApi({
            query: String(propmt).trim(),
            sede,
            tipo: tipoProvvedimento,
            year: String(year),
            numero: String(number),
          });
        }
        try {
          console.log(result.data);

          if (quota != -1) {
            await decreaseQuotaApi('searchQuota', 1);
          }

          // If the quota is -1, it means the user is not authenticated
          if (quota == -1) setNonAuthUsedQuota(nonAuthUsedQuota + 1);

          const run_id = result.data['run_id'];

          if (appState.unauthenticatedLogin) {
            const ip = await fetchUserIPApi();

            deepSearchObserver = onSnapshot(
              doc(firestore, 'unauthenticatedUsers', ip, 'deepSearch', run_id),
              (doc) => {
                console.log('Updates', doc.data());
                const data: any = doc.data();

                if (data) {
                  if (data['response'] !== undefined) {
                    setPending(false);

                    setAiSearchComposition(
                      data['response'] as AISearchCompositionModel
                    );
                  }

                  if (data['status'] == 'in_progress') {
                    setAiCompletionInProgress(true);
                  }

                  if (data['status'] == 'completed') {
                    setAiCompletionInProgress(false);
                  }
                }
              },
              (error) => {
                console.error(error);
                setPending(false);

                setToast({
                  open: true,
                  message: 'Errore durante la ricerca',
                  autoHideDuration: 6000,
                });
              }
            );
          } else {
            if (auth.currentUser == null) return;

            if (auth.currentUser?.uid) {
              deepSearchObserver = onSnapshot(
                doc(
                  firestore,
                  'users',
                  auth.currentUser.uid,
                  'deepSearch',
                  run_id
                ),
                (doc) => {
                  console.log('Updates', doc.data());
                  const data: any = doc.data();

                  if (data) {
                    if (data['response'] !== undefined) {
                      setPending(false);

                      setAiSearchComposition(
                        data['response'] as AISearchCompositionModel
                      );
                    }

                    if (data['status'] == 'in_progress') {
                      setAiCompletionInProgress(true);
                    }

                    if (data['status'] == 'completed') {
                      setAiCompletionInProgress(false);
                    }
                  }
                },
                (error) => {
                  console.error(error);
                  setPending(false);

                  setToast({
                    open: true,
                    message: 'Errore durante la ricerca',
                    autoHideDuration: 6000,
                  });
                }
              );
            } else {
              console.error('User is not authenticated');
            }
          }

          // setDeepSearchObserver(unsubscribe);
        } catch (e) {
          console.log(e);
          setPending(false);
        }
      } else {
        setToast({
          open: true,
          message: 'Quota esaurita',
          autoHideDuration: 6000,
        });

        setAppState((prevState) => ({
          ...prevState,
          upgradePanel: true,
        }));
      }
    } catch (e) {
      console.log(e);
      setPending(false);

      setToast({
        open: true,
        message: 'Errore durante la ricerca',
        autoHideDuration: 6000,
      });
    }
  };

  const performSeachAI = async () => {
    if (String(propmt).trim().length == 0) {
      setToast({
        open: true,
        message: 'Per favore, inserisci un testo per la ricerca',
        autoHideDuration: 6000,
      });
      return;
    }

    const quota = await checkQuotaApi('searchQuota');

    if (quota > 0) {
      setPending(true);

      setPromptStatic(propmt);

      setProvvedimenti([]);

      vectorSearchApi({
        query: String(propmt).trim(),
        sede,
        tipo: tipoProvvedimento,
        year: String(year),
        numero: String(number),
      })
        .then((result) => {
          console.log(result.data);

          decreaseQuotaApi('searchQuota', 1);

          setPending(false);

          setProvvedimenti(
            (result.data.results as any[]).map((e, i) => ({
              ...e,
              ...e.metadata,
            }))
          );
        })
        .catch((e) => {
          console.log(e);
          setPending(false);
        });
    } else {
      setToast({
        open: true,
        message: 'Quota esaurita',
        autoHideDuration: 6000,
      });
    }
  };

  const performSearch = async (loadMore?: boolean, page: number = 1) => {
    if (deepSearchObserver) {
      deepSearchObserver();
      deepSearchObserver = undefined;
    }
    if (String(propmt).trim().length == 0) {
      setToast({
        open: true,
        message: 'Per favore, inserisci un testo per la ricerca',
        autoHideDuration: 6000,
      });
      return;
    }

    const quota = await checkQuotaApi('searchQuota');

    if (quota > 0) {
      if (loadMore) {
        setPendingLoadingMore(true);
      }

      setPending(true);

      setPromptStatic(propmt);

      if (!loadMore) {
        setProvvedimenti([]);
      }

      textSearchApi({
        query: String(propmt).trim(),
        sede,
        tipo: tipoProvvedimento,
        year: String(year),
        numero: String(number),
        page,
      })
        .then((result) => {
          console.log(result.data);

          decreaseQuotaApi('searchQuota', 1);

          setPending(false);

          if (loadMore) {
            setPendingLoadingMore(false);
          }

          if (loadMore) {
            setProvvedimenti(
              provvedimenti.concat(
                (result.data.results as any[]).map((e, i) => ({
                  ...e,
                  ...e.metadata,
                }))
              )
            );
          } else {
            setProvvedimenti(
              (result.data.results as any[]).map((e, i) => ({
                ...e,
                ...e.metadata,
              }))
            );
          }
        })
        .catch((e) => {
          console.log(e);
          setPending(false);

          if (loadMore) {
            setPendingLoadingMore(false);
          }
        });
    } else {
      setToast({
        open: true,
        message: 'Quota esaurita',
        autoHideDuration: 6000,
      });

      setAppState((prevState) => ({
        ...prevState,
        upgradePanel: true,
      }));
    }
  };

  const handleKeyEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  useEffect(() => {
    //Restore the scroll to the top
    scrollToTop();

    const params = new URLSearchParams(window.location.search);
    const searchQuery = params.get('search');

    if (searchQuery) {
      setPrompt(searchQuery);
    }
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    getGeneralParameters();
  }, []);

  useEffect(() => {
    if (auth.currentUser) {
      getLatestDeepSearchHistory();
    }
  }, [auth.currentUser]);

  useEffect(() => {
    if (appState.unauthenticatedLogin) {
      getLatestDeepSearchHistory();
    }
  }, [appState.unauthenticatedLogin]);

  const memoizedProvvedimento = useMemo(
    () =>
      provvedimenti.map((e) => (
        <Provvedimento
          key={e.id}
          data={e}
          query={promptStatic}
          sx={{
            marginTop: '10px',
          }}
        />
      )),
    [promptStatic, provvedimenti]
  );

  function handleHandleChecked(
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void {
    setUsingAISearch(checked);
  }

  function handleSearch() {
    if (usingAISearch) {
      //   performSeachAI();

      performDeepSearchAI();
    } else {
      performSearch();
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: '#f9f9f9',
        minHeight: 'calc(100vh - 63.99px)',
      }}
    >
      <Container
        sx={{
          paddingBottom: 5,
        }}
      >
        <Breadcrumbs aria-label="breadcrumb" sx={{ pt: 2 }}>
          <Link
            underline="hover"
            color="inherit"
            sx={{
              cursor: 'pointer',
            }}
            onClick={() => {
              navigate('/panel/home');
            }}
          >
            Home
          </Link>

          <Typography sx={{ color: 'text.primary' }}>
            Giustizia Amministrativa
          </Typography>
        </Breadcrumbs>

        {/* <Typography mt={2}>Sentenze disponibili</Typography> */}
        {/*screenWidth > 1000 ? <YearsBar years={anni.slice(0, 25)} /> : null */}

        {/* {parameters ? (
          <LinearProgressWithLabel
            value={
              usingAISearch
                ? parameters!.availableSentencesPercentageAI
                : parameters!.availableSentencesPercentage
            }
          />
        ) : (
          <LinearProgress
            sx={{
              marginTop: 1,
            }}
          />
        )} */}

        {/*
        <Box sx={{ mt: 2, mb: 2 }}>
          <Alert
            icon={<Info fontSize="inherit" />}
            severity="info"
            sx={{
              borderColor: 'info.light',
              borderWidth: '1px',
              borderStyle: 'solid',
            }}
          >
            Ogni giorno nuove sentenze saranno caricate su Giuribot.
          </Alert>
        </Box>
        */}

        <Box
          sx={{
            border: '1px solid #ccc',
            mt: 2,
            borderRadius: '4px',
            backgroundColor: 'white',
            p: 3,
          }}
        >
          <Box width={'100%'}>
            <Stack
              direction={'row'}
              sx={{
                display: 'grid',
                justifyContent: 'center',
                gap: 2,
                gridTemplateColumns: {
                  // 3 columns for larger screens
                  xs: '1fr', // default to 1 column for the smallest screens
                  sm: 'repeat(2, 1fr)', // 2 columns for small screens
                  md: 'repeat(4, minmax(min(100%, 150px), 350px))',
                  lg: 'repeat(4, minmax(min(100%, 150px), 350px))',
                  xg: 'repeat(4, minmax(min(100%, 150px), 350px))', // 3 columns for medium and larger screens
                },
              }}
            >
              <Box flex={1}>
                <FormControl fullWidth disabled={pending} size="small">
                  <FormLabel>Tipo Provvedimento</FormLabel>
                  <Select
                    value={tipoProvvedimento}
                    onChange={onChangeProvvedimento}
                    fullWidth
                    variant="outlined"
                    displayEmpty
                  >
                    <MenuItem key={'tipo-provvedimento'} value="">
                      <em style={{ color: 'rgb(120, 120, 120)' }}>Tutti</em>
                    </MenuItem>
                    {provvedimentiTypes.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box flex={1}>
                <FormControl fullWidth disabled={pending} size="small">
                  <FormLabel>Sede</FormLabel>
                  <Select
                    value={sede}
                    onChange={onChangeSede}
                    fullWidth
                    variant="outlined"
                    displayEmpty
                  >
                    <MenuItem key={'tipo-sede'} value="">
                      <em style={{ color: 'rgb(120, 120, 120)' }}>Tutti</em>
                    </MenuItem>
                    {sedi.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box flex={1}>
                <FormControl fullWidth disabled={pending} size="small">
                  <FormLabel>Anno</FormLabel>
                  <Select
                    value={year}
                    onChange={onChangeYear}
                    fullWidth
                    variant="outlined"
                    placeholder="Anno"
                    displayEmpty
                  >
                    <MenuItem key={'tipo-anno'} value="">
                      <em style={{ color: 'rgb(120, 120, 120)' }}>Tutti</em>
                    </MenuItem>
                    {anni.map((option) => (
                      <MenuItem key={option.value} value={option.label}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              <Box flex={1} display={'flex'}>
                <FormControl fullWidth disabled={pending} size="small">
                  <FormLabel>Numero</FormLabel>
                  <TextField
                    value={number}
                    variant="outlined"
                    placeholder="Numero del provvedimento"
                    fullWidth
                    size="small"
                    onChange={onChangeNumber}
                  />
                </FormControl>
              </Box>
            </Stack>
          </Box>

          <Stack direction={'row'} spacing={2} mt={2}>
            <Box
              flex={5}
              display={{
                xs: 'block',
                sm: 'flex',
                md: 'flex',
              }}
              alignItems={'center'}
            >
              <ChatIconButton
                height={screenWidth > 600 ? 55 : 40}
                width={screenWidth > 600 ? undefined : '100%'}
                state={usingAISearch}
                onStateChanged={(e) => setUsingAISearch(e)}
                buttonType="ai_search"
                borderRadius={1.0}
                disabled={pending}
              >
                Ricerca AI
              </ChatIconButton>
              <OutlinedInput
                placeholder={
                  usingAISearch
                    ? 'Inserisci una descrizione o parole chiave dei provvedimenti'
                    : 'Inserisci una descrizione o parola chiave dei provvedimenti'
                }
                fullWidth
                value={propmt}
                onKeyUp={handleKeyEvent}
                onChange={onChangePrompt}
                disabled={pending}
                endAdornment={
                  <Button
                    disabled={pending}
                    variant="contained"
                    disableElevation
                    endIcon={
                      screenWidth > 600 ? (
                        <ArrowForward fontSize="small" />
                      ) : null
                    }
                    onClick={handleSearch}
                    sx={{
                      color: 'white',
                      px: 3,

                      backgroundColor: 'black',
                      fontWeight: 600,
                      '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                      },
                    }}
                  >
                    {screenWidth > 600 ? (
                      'Cerca'
                    ) : (
                      <ArrowForward fontSize="small" />
                    )}
                  </Button>
                }
                sx={{
                  flex: 1,
                  marginLeft: {
                    xs: '0px',
                    sm: '10px',
                  },
                  paddingLeft: {
                    xs: '0px',
                    sm: '20px',
                  },
                  marginTop: {
                    xs: '10px',
                    sm: '0px',
                  },
                }}
              />
            </Box>
          </Stack>
        </Box>

        <Box>
          {pending ? (
            <Stack justifyContent={'center'} mb={1}>
              <LinearProgress />

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingLeft: '30px',
                }}
              >
                <Typography
                  mt={2}
                  color={extendedTheme.palette.primary.main}
                  sx={{
                    animation: 'blinkingText 1.5s infinite',
                    '@keyframes blinkingText': {
                      '0%': { opacity: 1 },
                      '50%': { opacity: 0.5 },
                      '100%': { opacity: 1 },
                    },
                  }}
                >
                  Cercando...
                </Typography>
              </Box>
            </Stack>
          ) : null}
          {!usingAISearch ? memoizedProvvedimento : null}

          {usingAISearch ? (
            <>
              <Typography variant="h6" mt={2}>
                Ricerche recenti
              </Typography>
              <Stack
                direction={'row'}
                justifyContent={'left'}
                spacing={0}
                flexWrap={'wrap'}
                rowGap={2}
                columnGap={2}
                mt={2}
                mb={2}
              >
                {deepSearchHistory.map((e) => (
                  <Chip
                    variant={
                      e.query === aiSearchComposition?.query
                        ? 'filled'
                        : 'outlined'
                    }
                    label={e.query}
                    clickable
                    disabled={pending}
                    onClick={(el) => {
                      setAiSearchComposition(e);
                      setPrompt(el.currentTarget.textContent || '');
                    }}
                  />
                ))}
              </Stack>
              {aiSearchComposition ? (
                <>
                  {aiCompletionInProgress ? (
                    <Box>
                      <Typography variant="h6" mb={2}>
                        Generazione del riepilogo in corso...
                      </Typography>
                      <Box
                        sx={{
                          width: '100%',
                          height: '20px',
                          backgroundColor: '#f0f0f0',
                          borderRadius: '4px',
                          animation: 'blinkingText 1.5s infinite',
                          '@keyframes blinkingText': {
                            '0%': { opacity: 1 },
                            '50%': { opacity: 0.5 },
                            '100%': { opacity: 1 },
                          },
                        }}
                      />
                      <Box
                        sx={{
                          width: '80%',
                          height: '20px',
                          backgroundColor: '#f0f0f0',
                          borderRadius: '4px',
                          animation: 'blinkingText 1.5s infinite',
                          '@keyframes blinkingText': {
                            '0%': { opacity: 1 },
                            '50%': { opacity: 0.5 },
                            '100%': { opacity: 1 },
                          },
                          mt: 1,
                        }}
                      />
                      <Box
                        sx={{
                          width: '90%',
                          height: '20px',
                          backgroundColor: '#f0f0f0',
                          borderRadius: '4px',
                          animation: 'blinkingText 1.5s infinite',
                          '@keyframes blinkingText': {
                            '0%': { opacity: 1 },
                            '50%': { opacity: 0.5 },
                            '100%': { opacity: 1 },
                          },
                          mt: 1,
                        }}
                      />
                    </Box>
                  ) : (
                    <AIMessageContainer>
                      {aiSearchComposition.summary}
                    </AIMessageContainer>
                  )}

                  <Box mt={2}>
                    <Typography variant="h6">Sentenze più rilevanti</Typography>

                    <Stack spacing={2} mt={2}>
                      {aiSearchComposition.text_search_results.length === 0 &&
                      aiCompletionInProgress ? (
                        <LinearProgress />
                      ) : (
                        aiSearchComposition.text_search_results
                          .map(
                            (el) =>
                              ({
                                id: el.metadata.url,
                                context: '',
                                numeroProvv: el.metadata.numeroProvv,
                                numeroRicorso: el.metadata.numeroRicorso,
                                content: el.text,
                                url: el.metadata.url,
                                numeroProvvedimento: el.metadata.numeroProvv,
                                title: el.metadata.title,
                                year: el.metadata.year,
                                sede: el.metadata.sede,
                                tipo: el.metadata.tipo,
                              } as SentencesModel)
                          )
                          .map((e) => (
                            <Provvedimento
                              key={e.url}
                              data={{
                                ...e,
                                chunk: e.content,
                                id: e.url,
                                context: '',
                                numeroProvv: e.numeroProvvedimento,
                                appello: '',
                                hasAppello: false,
                                pages: [],
                                embedding: [],
                                provvAppello: '',
                                numeroRicorso: e.numeroRicorso,
                                text: e.content,
                              }}
                              query={promptStatic}
                              sx={{
                                marginTop: '10px',
                              }}
                            />
                          ))
                      )}
                    </Stack>
                  </Box>
                </>
              ) : null}
            </>
          ) : null}

          {!usingAISearch ? (
            provvedimenti.length >= 20 ? (
              <Stack direction={'row'} justifyContent={'center'} mt={2}>
                {pendingLoadingMore ? (
                  <CircularProgress />
                ) : (
                  <Button
                    onClick={() => {
                      performSearch(true, page + 1);
                      setPage(page + 1);
                    }}
                  >
                    Carica di più
                  </Button>
                )}
              </Stack>
            ) : null
          ) : null}
        </Box>
      </Container>
    </Box>
  );
};
