import { httpRequest } from './base';

export const getSentenceApi = (id: string) => {
  return httpRequest({
    url: `${process.env.REACT_APP_SERVER_SEARCH_URL}/sentences/${id}`,
    method: 'POST',
  });
};

export const getSentenceByUrlApi = (url: string) => {
  const encodedUrl = encodeURIComponent(url);
  return httpRequest({
    url: `${process.env.REACT_APP_SERVER_SEARCH_URL}/sentences?url=${encodedUrl}`,
    data: { url: encodedUrl },
    method: 'POST',
  });
};
